import React, {useState} from 'react'
import { connect } from 'react-redux';
import { toggleDetailsModalAction } from '../../store/actions/resultDetailsActions';
import {queryDetailsAction} from '../../store/actions/searchActions';
import {detailParams, itemInfo, getTextClass} from './ResultFunctions';
import NewResultDetails from './NewResultDetails';
import { HOLY_QURAN_INDEX } from '../../backendInfo';


const ResultItem = ({ resultItem, tab, qtype }) => {

    const [openNewDetails, toggleOpen] = useState(false)
    
    const handleClick = (e) => {
        toggleOpen(true)
    }

    const subItemInfoStyles = {
        color: 'gray',
        marginTop: '0px',
        marginBottom: '0px',
        paddingBottom: '15px'
    }

    const ItemTextRenderer = props => {
        const { hit } = props
        if (hit._index !== HOLY_QURAN_INDEX){
            return <p 
                    onClick={resultItem => handleClick(resultItem)} 
                    className={getTextClass(hit._source.lang) + " snippet"}
                    dangerouslySetInnerHTML={{__html: hit.highlight['text'][0].replace(/\n/g, '<br>')}}                ></p>
            }
        else{
            const elements = Object.entries(hit.highlight).map(hField => {
                const fieldName = hField[0]
                const infoFieldName = fieldName + '_info'
                const langFieldName = fieldName + '_lang'
                return <div onClick={handleClick} className="snippet">
                    <p
                    style={{marginBottom:'0', marginTop:'0', paddingTop:'10px'}}
                    className={getTextClass(hit._source[langFieldName])}
                    dangerouslySetInnerHTML={{__html: hit.highlight[fieldName]}}
                    ></p>
                    <p
                    style={subItemInfoStyles}
                    className={getTextClass(hit._source[langFieldName])}
                    dangerouslySetInnerHTML={{__html: hit._source[infoFieldName]}}
                    ></p>
                </div>               
            })
            return elements
        }
    }
    

    return (
        <div className='result-item hoverable'>
            <ItemTextRenderer hit={resultItem}/>
            
            <div className="reference-info">
            <p className={resultItem._index === "arabic_books" ? "arabic-font" : ""} style={resultItem._index === "arabic_books" ? {fontSize: "1.3rem"} : {}}>{itemInfo(resultItem._source, resultItem._index)}</p>

            </div>

            {openNewDetails && <NewResultDetails 
                isOpen={openNewDetails} 
                onClose={() => toggleOpen(false)}
                queryParams={detailParams(resultItem._source, resultItem._index, qtype)}
            />}
        </div>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        queryDetails: params => dispatch(queryDetailsAction(params)),
        openDetailsModal: () => dispatch(toggleDetailsModalAction(true)),
    }
}

const mapStateToProps = state => {
    return {
        tab: state.filters.tab,
        qtype: state.filters.qtype
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultItem);