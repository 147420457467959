import React, {useState} from "react";
import MyCheckbox from '../layout/Checkbox';
import { topics } from './Meta';
import { addChapterAction, removeChapterAction } from "../../store/actions/filtersActions";
import { connect } from "react-redux";

const SubCollectionFilters = props => {
    const {display, collID, vno, chapters, addChapter, removeChapter} = props
    const volTopics = topics[collID] && topics[collID][vno]
    const topicID = topic => topic
    
    const handleChange = (e, topic) => {
        e.target.checked ? addChapter(topicID(topic)) : removeChapter(topicID(topic))
    }

    return display && volTopics ? <>
        {
            volTopics.map((topic_obj, key) => {
                return <MyCheckbox 
                        key={key}
                        label={topic_obj.topic}
                        onChange={e => handleChange(e, topic_obj.id)}
                        checked={chapters.includes(topicID(topic_obj.id))}
                        />
            })
        }
    </> : <></>

}

const mapDispatchToProps = dispatch => {
    return {
        addChapter: chapter => dispatch(addChapterAction(chapter)),
        removeChapter: chapter => dispatch(removeChapterAction(chapter))
    }
}

const mapStateToProps = state => {
    return {chapters: state.filters.chapters}
}


export default connect(mapStateToProps, mapDispatchToProps)(SubCollectionFilters)
