import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeLangAction } from '../../store/actions/langActions';
import Switch from './Switch';
import { LocalizationContext } from '../../localizationContext';

class LangToggler extends Component {
    static contextType = LocalizationContext;

    state = { lang: 'eng' }

    componentDidMount() {
        const lang = localStorage.getItem('lang-pref') || 'eng';
        this.setLang(lang);
    }

    setLang = (lang) => {
        this.setState({ lang });
        localStorage.setItem('lang-pref', lang);
        this.props.changeLang(lang);
        this.context.changeLanguage(lang);
    }

    toggleLang = (e) => {
        const newLang = e.target.checked ? 'ar' : 'eng';
        this.setLang(newLang);
    }

    render() {
        return <Switch label={"Arabic Language"} checked={this.state.lang === 'ar'} onChange={this.toggleLang} />
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLang: (lang) => dispatch(changeLangAction(lang))
    }
}

export default connect(null, mapDispatchToProps)(LangToggler);