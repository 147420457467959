import { COLLECTION_NAMES, AUTHOR_NAMES, VOLUMES, COLLECTIONS_BY_AUTHOR, OTHER_COLLECTIONS } from './Meta';
import { searchAction } from '../../store/actions/searchActions';
import Collapsible from '../layout/Collapsible';
import Tooltip from '@material-ui/core/Tooltip';
import { 
    toggleModalAction, 
    addCollectionsAction, 
    addVolumesAction, 
    addAuthorsAction,
    addCollectionAction,
    addAuthorAction, 
    addVolAction,
    removeAuthorAction,
    removeCollectionAction,
    removeCollectionsAction,
    removeAuthorsAction,
    removeVolAction 
} from '../../store/actions/filtersActions';
import VolumesSection from './VolumesSection';
import MyCheckbox from '../layout/Checkbox';
import React, {Component} from 'react';
import {connect} from 'react-redux';

class BooksFilters extends Component{
    constructor(props){
        super(props)
        this.handleAuthorCheckbox = this.handleAuthorCheckbox.bind(this)
        this.handleCollectionCheckbox = this.handleCollectionCheckbox.bind(this)
        this.showCollections = this.showCollections.bind(this)
        this.selectAllCollections = this.selectAllCollections.bind(this)
        this.selectAllAuthors = this.selectAllAuthors.bind(this)
        this.applyFilters = this.applyFilters.bind(this)
        this.showOtherCollections = this.showOtherCollections.bind(this)

    }

    state = {
        selected: {
            authors: [],
            collections: [],
            vols: {}
        }
    }

    handleAuthorCheckbox(e){
        const aid = e.target.id.split('-')[1]
        if(e.target.checked){
            this.props.addAuthor(aid)
        }
        else{
            this.props.removeAuthor(aid)
        }
    }

    handleCollectionCheckbox(e){
        const cid = e.target.id.split('-')[2]
        if(e.target.checked){
            this.props.addCollection(cid)
        }
        else{
            this.props.removeCollection(cid)
        }
    }

    selectAllCollections(e){
        let aid = null
        let newSelected = []
        const collDivs = e.target.parentNode.parentNode.nextSibling.firstChild.childNodes
        collDivs.forEach(div => {
            const checkboxID = div.firstChild.firstChild.id
            const cid = checkboxID.split('-')[2]
            aid = checkboxID.split('-')[1]
            newSelected = [...newSelected, cid]
        })
        
        if(e.target.checked){
            this.props.addCollections(newSelected)
        }
        else{
            this.props.removeCollections()
        }
    }

    selectAllAuthors(e){
        let newSelected = []
        const authDivs = e.target.parentNode.nextSibling.childNodes
        
        authDivs.forEach(div => {
            const aid = div.firstChild.firstChild.id.split('-')[1]
            newSelected = [...newSelected, aid]
        })

        if(e.target.checked){
            this.props.addAuthors(newSelected)
        }
        else{
            this.props.removeAuthors()
        }
    }

    showCollections(){
        return Object.entries(COLLECTIONS_BY_AUTHOR).map(cbaEntry => {
            const aid = cbaEntry[0]
            const collIds = cbaEntry[1]
            const aname = AUTHOR_NAMES[aid]
            
            return <div id={`coll-${aid}`} key={aid}>
                    <Collapsible 
                        defaultExpanded={false}
                        header={aname}
                        preBody={ <MyCheckbox label="Select all" onChange={this.selectAllCollections}/> }
                        body={
                            <div className="filters-flex">
                                {collIds.map(cid => {
                                    return (
                                        <div key={aid+cid}>
                                            <Tooltip title={COLLECTION_NAMES[cid]} placement="top" enterDelay={500} enterNextDelay={500} enterTouchDelay={500}>
                                                <MyCheckbox 
                                                  id={`coll-${aid}-${String(cid)}`} 
                                                  checked={this.props.filters.collections.includes(String(cid))} 
                                                  label={COLLECTION_NAMES[cid]} 
                                                  onChange={this.handleCollectionCheckbox}
                                                  />
                                            </Tooltip>
                                            <VolumesSection 
                                              display={this.props.filters.collections.includes(String(cid))}
                                              noOfVols={VOLUMES[cid]}
                                              collID={cid} 
                                              />
                                        </div>
                                    )
                                })}                
                            </div>
                        }
                    />
                </div>
        })
    }

    showAuthors(){
        return (
            <div className="authors filters-flex">
            {Object.entries(AUTHOR_NAMES).map(authEntry => {
                const aid = authEntry[0]
                return (<div key={aid}>
                        <MyCheckbox 
                         onChange={this.handleAuthorCheckbox} 
                         checked={this.props.filters.authors.includes(String(aid))} 
                         id={`auth-${aid}`} label={authEntry[1]}/>
                    </div>
                    )
                })
            }
            </div>
        )
    }

    showOtherCollections(){
        return <div className="filters-flex">
            {Object.entries(OTHER_COLLECTIONS).map(entry => {
            const cid = entry[0]
            const collName = entry[1]
            return <div key={cid}>
                        <MyCheckbox 
                            id={`coll--${String(cid)}`} 
                            checked={this.props.filters.collections.includes(String(cid))} 
                            label={collName} 
                            onChange={this.handleCollectionCheckbox}
                        />
                        <VolumesSection 
                            display={this.props.filters.collections.includes(String(cid))}
                            noOfVols={VOLUMES[cid]}
                            collID={cid} 
                        />    
                    </div>
                })
            }
        </div>
    }

    applyFilters(){
        this.props.perfromSearch( document.querySelector("#search_field").value,  this.props.filters )
        this.props.toggleModal(false)
    }

    render(){
        return <>
                <legend>Authors</legend>
                <MyCheckbox label="Select all" onChange={this.selectAllAuthors}/>
                {this.showAuthors()}                
                <legend>Collections</legend>
                {this.showCollections()}
                <legend>Others</legend>
                {this.showOtherCollections()}
                <div className="apply-filters-container">
                    <button onClick={this.applyFilters} className="btn apply-filters-btn margin-auto display-block">Apply and Search</button>
                </div>
            </>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        perfromSearch: (term, filters) => dispatch(searchAction(term, 0, filters)),
        addAuthors: authors => dispatch(addAuthorsAction(authors)),
        addCollections: colls => dispatch(addCollectionsAction(colls)),
        addVolumes: vols => dispatch(addVolumesAction(vols)),
        addCollection: coll => dispatch(addCollectionAction(coll)),
        addAuthor: auth => dispatch(addAuthorAction(auth)),
        addVolume: (vno, collID) => dispatch(addVolAction(vno, collID)),
        removeCollection: coll => dispatch(removeCollectionAction(coll)),
        removeCollections: () => dispatch(removeCollectionsAction()),
        removeAuthor: auth => dispatch(removeAuthorAction(auth)),
        removeAuthors: () => dispatch(removeAuthorsAction()),
        removeVolume: (vno, collID) => dispatch(removeVolAction(vno, collID)),
        toggleModal: value => dispatch(toggleModalAction(value)),
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BooksFilters);