import { backendUrl, queryPath, queryDetailPath } from '../../backendInfo';
import { showResultsAction, clearResultsAction } from './resultsActions';
import { showMessageAction, clearMessageAction } from './messageActions';
import { authenticateAction, setAwaitAuthAction } from './authActions';
import { showDetailsAction } from './resultDetailsActions';
import Cookies from 'js-cookie';
import axios from 'axios';


const createParams = (term, filters, offset=0) => {
    delete filters['isOpen']
    filters.collections = filters.collections.join(",")
    filters.authors = filters.authors.join(",")
    filters.volumes = filters.volumes.join(',')
    filters.chapters = filters.chapters.join('||')
    filters.term = term
    filters.offset = offset
    return filters
}

export const searchAction = (term, offset=0, filters=null) => { //filters is the entire state of filtersReducer
    const localFilters = JSON.parse(JSON.stringify(filters))
    const queryUrl = `${backendUrl}${queryPath}`

    return (dispatch, getState) => {
        // authenticate
        const access = Cookies.get('token');
        if (String(access) !== "null"){
            dispatch(awaitSearchAction(true))
            
            // scroll load or not
            if (offset === 0){
                dispatch(clearResultsAction())
                dispatch(clearMessageAction())
                dispatch(searchEndedAction(false))
            }
            
            // construct query params
            const params = createParams(term, localFilters, offset)

            // do the search and get results
            axios.get(queryUrl, { params, headers: { Authorization: `Bearer ${access}` } })
            .then(res => {
                dispatch(awaitSearchAction(false))
                if(res.data.hits && res.data.hits.total.value === 0){
                    dispatch(showMessageAction("No results found"))
                }
                else if(res.data.hits){
                    if (res.data.hits.hits.length === 0){ // reached the end
                        dispatch(searchEndedAction(true))
                        dispatch(showMessageAction("The end."))
                        console.log("End reached")
                    }
                    else{
                        dispatch(clearMessageAction())
                        dispatch(showResultsAction(res.data))
                        dispatch(setSearchTermAction(res.config.params.term)) // for scrolling load
                    }
                }
                else{
                    console.log("false res data hits")
                    dispatch(showMessageAction(res.data))
                }
                if(offset === 0) // save history
                    setTimeout(() => document.getElementById("refresh-history") ? document.getElementById("refresh-history").click() : null, 3000)
            })
            .catch(err => {
                dispatch(awaitSearchAction(false))
                dispatch(searchEndedAction(true))
                console.log("ERROR: ", err)
                if(err.response && err.response.status === 401){
                    setAwaitAuthAction(true)
                    if(offset === 0){
                        dispatch(authenticateAction(false))
                    }
                    else{
                        window.onscroll = null
                        dispatch(showMessageAction("You have been logged out. Please sign in again."))
                        setTimeout(() => {
                            dispatch(authenticateAction(false))
                        }, 2000)
                    }
                }
                else{ // probably the server's down
                    dispatch(showMessageAction("Something went wrong. Please try again."))
                }
            })
        }
        else{
            dispatch(awaitSearchAction(false))
            dispatch(searchEndedAction(true))
            dispatch(authenticateAction(false))
        }

    }
}

export const queryDetailsAction = (params) => {
    return (dispatch, getState) => {
        const access = Cookies.get('token');
        if(String(access) !== "null"){
               
            axios.get(`${backendUrl}${queryDetailPath}`, { params, headers: { Authorization: `Bearer ${access}` } })
            .then(res => {
                dispatch(showDetailsAction(res.data.hits.hits))
            })
        }
        else{
            dispatch(awaitSearchAction(false))
            dispatch(authenticateAction(false))
        }

    }
}

export const awaitSearchAction = (value) => {
    return { type: 'CHANGE_AWAIT_SEARCH', value}
}

export const setSearchTermAction = (term) => {
    return { type: 'SET_SEARCH_TERM', term }
}

export const searchEndedAction = (value) => {
    return {type: 'SEARCH_ENDED', value}
}


