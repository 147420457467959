import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Component } from 'react';

class MySpinner extends Component{
    render(){
        if(this.props.display){
            return (
                <div className="margin-auto width-content">
                    <CircularProgress color="secondary" />
                </div>
            )
        }
        else{
            return <div></div>
        }
    }
}

export default MySpinner;