import { searchAction } from '../../store/actions/searchActions';
import Collapsible from '../layout/Collapsible';
import { 
    toggleModalAction, 
    addCollectionsAction, 
    addVolumesAction, 
    addAuthorsAction,
    addCollectionAction,
    addAuthorAction, 
    addVolAction,
    removeAuthorAction,
    removeCollectionAction,
    removeCollectionsAction,
    removeAuthorsAction,
    removeVolAction 
} from '../../store/actions/filtersActions';
import MyCheckbox from '../layout/Checkbox';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchFilters} from './FetchFilters';

class EngBooks extends Component{
    constructor(props){
        super(props)
        this.handleAuthorCheckbox = this.handleAuthorCheckbox.bind(this)
        this.handleCollectionCheckbox = this.handleCollectionCheckbox.bind(this)
        this.showCollections = this.showCollections.bind(this)
        this.selectAllCollections = this.selectAllCollections.bind(this)
        this.selectAllAuthors = this.selectAllAuthors.bind(this)
        this.applyFilters = this.applyFilters.bind(this)
    }

    componentDidMount(){
        fetchFilters('engBooks')
        .then(res => {
            const [authors, collsByAuthors, otherColls] = res
            this.setState({filtersResponse: {collsByAuthors, authors}})
        })
    }

    state = {
        selected: {
            authors: [],
            collections: [],
            vols: {}
        },
        filtersResponse: null
    }

    handleAuthorCheckbox(e){
        const aid = e.target.id
        if(e.target.checked){
            this.props.addAuthor(aid)
        }
        else{
            this.props.removeAuthor(aid)
        }
    }

    handleCollectionCheckbox(e){
        const cid = e.target.id
        if(e.target.checked){
            this.props.addCollection(cid)
        }
        else{
            this.props.removeCollection(cid)
        }
    }

    selectAllCollections(e){
        let newSelected = []
        const collDivs = e.target.parentNode.parentNode.nextSibling.firstChild.childNodes
        collDivs.forEach(div => {
            const checkboxID = div.firstChild.firstChild.id
            const cid = checkboxID
            newSelected = [...newSelected, cid]
        })

        e.target.checked ? this.props.addCollections(newSelected) : this.props.removeCollections()
    
    }

    selectAllAuthors(e){
        let newSelected = []
        const authDivs = e.target.parentNode.nextSibling.childNodes
        
        authDivs.forEach(div => {
            const aid = div.firstChild.firstChild.id
            newSelected = [...newSelected, aid]
        })

        if(e.target.checked){
            this.props.addAuthors(newSelected)
        }
        else{
            this.props.removeAuthors()
        }
    }

    showCollections(collsByAuthors){
        return Object.entries(collsByAuthors).map((cbaEntry, index) => {
            const aname = cbaEntry[0]
            const collections = cbaEntry[1]
            return (
            <div key={index}>
                <Collapsible 
                defaultExpanded={false}
                header={aname}
                preBody={ <MyCheckbox label="Select all" onChange={this.selectAllCollections}/> }
                body={
                <div className="filters-flex">
                    {Object.entries(collections).map((collEntry, index) => {
                        const collName = collEntry[1]['collName'].replaceAll('_', ' ')
                        const collID = collEntry[1]['collID']
                        return (
                            <div key={index}>
                                <MyCheckbox 
                                id={collID} 
                                checked={this.props.filters.collections.includes(collID)} 
                                label={collName} 
                                onChange={this.handleCollectionCheckbox}
                                />
                            </div>
                        )
                    })}                
                </div>}
                />
            </div>)
        })
    }

    showAuthors(authors){
        return (
            <div className="authors filters-flex">
            {authors.map((author, index) => {
                const {authorID, authorName} = author
                return (
                <div key={index}>
                    <MyCheckbox 
                    id={authorID} 
                    onChange={this.handleAuthorCheckbox} 
                    checked={this.props.filters.authors.includes(authorID)} 
                    label={authorName}
                    />
                </div>
                )})
            }
            </div>
        )
    }

    applyFilters(){
        this.props.perfromSearch( document.querySelector("#search_field").value,  this.props.filters )
        this.props.toggleModal(false)
    }

    render(){
        return this.state.filtersResponse ? (
            <div>
                <legend>Authors</legend>
                <MyCheckbox label="Select all" onChange={this.selectAllAuthors}/>
                {this.showAuthors(this.state.filtersResponse.authors)}                
                <legend>Collections</legend>
                {this.showCollections(this.state.filtersResponse.collsByAuthors)}
                <div className="apply-filters-container">
                    <button onClick={this.applyFilters} className="btn apply-filters-btn margin-auto display-block">Apply and Search</button>
                </div>
            </div>
        ) : (<div>Loading...</div>)

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        perfromSearch: (term, filters) => dispatch(searchAction(term, 0, filters)),
        addAuthors: authors => dispatch(addAuthorsAction(authors)),
        addCollections: colls => dispatch(addCollectionsAction(colls)),
        addVolumes: vols => dispatch(addVolumesAction(vols)),
        addCollection: coll => dispatch(addCollectionAction(coll)),
        addAuthor: auth => dispatch(addAuthorAction(auth)),
        addVolume: (vno, collID) => dispatch(addVolAction(vno, collID)),
        removeCollection: coll => dispatch(removeCollectionAction(coll)),
        removeCollections: () => dispatch(removeCollectionsAction()),
        removeAuthor: auth => dispatch(removeAuthorAction(auth)),
        removeAuthors: () => dispatch(removeAuthorsAction()),
        removeVolume: (vno, collID) => dispatch(removeVolAction(vno, collID)),
        toggleModal: value => dispatch(toggleModalAction(value)),
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EngBooks);