import React, { useState, useEffect, useRef } from 'react';

const ARABIC_BOOKS_DISCLAIMER= `
يتحمل فريق منة العليم المسؤولية الكاملة عن أي أخطاء أو مغالطات قد تحدث على هذه المنصة.\n
يتم إنشاء المحتوى على هذه المنصة من خلال عملية التعرف الضوئي على الأحرف (OCR) للكتب والدوريات. نظرًا لقيود تقنية OCR، لا سيما بالنسبة للغات غير الإنجليزية مثل الأردية والعربية، فمن المحتمل أن يحتوي النص الناتج على أخطاء إملائية ونحوية ومغالطات نصية.\n
إذا كنت ترغب في الاقتباس من كتاب أو مقال، يرجى الرجوع إلى النسخة الأصلية بصيغة PDF ولا تقتبس المراجع المنسوخة من محرك البحث هذا.\n
نيتنا هي توفير محرك بحث يغذي الشغف لاستكشاف الحكمة العميقة في كتابات المسيح الموعود (عليه السلام) والخلفاء (رضي الله عنهم). على الرغم من احتمالية وجود أخطاء قائمة على OCR، نحن نسعى جاهدين لتوفير أداة تسمح للقراء بالانغماس في جمال هذه النصوص الموقرة. نطلب منكم بكل احترام تفهم أي أوجه قصور ونشجعكم على استخدام قسم الملاحظات للإبلاغ عن الأخطاء، مما يمكننا من تصحيح المشكلات على الفور والتحسين المستمر لدقة وموثوقية هذا المورد القيم.
`
const URDU_BOOKS_DISCLAIMER=`
منتہ العلیم کی ٹیم اس ویب سائٹ پر ہونے والی کسی بھی قسم کی غلطی کی پوری ذمہ داری لیتی ہے۔\n
اس ویب سائٹ پر موجود مواد کتابوں اور رسالوں سے آپٹیکل کریکٹر ریکگنیشن (Optical Character Recognition, OCR) کے ذریعے سے تیار کیا جاتا ہے۔ آپٹیکل کریکٹر ریکگنیشن کی ٹیکنالوجی خاص کر اردو اور عربی کو صحیح طرز پر پہچان نہیں پاتی۔ جس کی وجہ سے متن میں ہجے، گرامر اور الفاظ کی غلطیاں ہو سکتی ہیں۔\n
اگر کسی کتاب یا تحریر کا حوالہ دینا ہو تو برائے مہربانی اصلی پی ڈی ایف (PDF) کی طرف رجوع کریں۔ اس سرچ انجن سے حوالے نقل نہ کریں۔ \n
ہمارا مقصد ایسا سرچ انجن بنانا ہے جو حضرت مسیح موعود علیہ الصلوٰۃ والسلام اور خلفاء رضی اللہ عنہم کی تحریروں میں موجود علمی خزائن کو ڈھونڈنے کی خواہش کو فروغ دیتا ہے۔ ان تمام مسائل کے باوجود، ہم قارئین کو علمی خزائن سے پُر تحریروں سے مستفید ہونے کا موقع باہم فراہم کر رہے ہیں۔\n
کسی بھی رہ جانے والی کوتاہی کے لیے ہم صدق دل سے معذرت خواہ ہیں اور آپ سے درخواست گزار ہیں کہ براہ کرم فیڈ بیک سیکشن میں ہماری غلطیوں کی نشاندہی کریں تاکہ ہم فوراً ان کی اصلاح کر سکیں اور اس کاوش کو مزید درست اور معتبر بنا سکیں۔`

const ALFAZAL_DISCLAIMER=`
The Minnatul Aleem team takes full responsibility for any errors or inaccuracies that may occur on this platform.\n
The content on this platform is created through the process of Optical Character Recognition (OCR) of books and periodicals. Due to the limitations of OCR technology, particularly for non-English languages such as Urdu and Arabic, the resulting text is likely to contain spelling mistakes, grammatical errors, and textual inaccuracies.\n
The Al Fazl periodical is produced using OCR. However, Al Fazl data contains more mistakes. Al Fazl periodical often has multiple columns on the same page, leading to text from different columns getting mixed. This means the OCRd text may not make complete sense. However, the words from the OCR are from the correct page. Thus, the OCRd text is more like an index and is more accurate if used to check individual words mentioned on a page rather than entire sentences. Therefore, please treat Al Fazl data as an index of Al Fazl rather than full text OCR.\n
If you wish to quote a book or article, please refer to the original PDF version and do not cite references copied from this search engine.\n
Our intention is to provide a search engine that fuels the passion for exploring the profound wisdom within the writings of the Promised Messiah (as) and the Khulafa (ra). Despite the potential for OCR-based errors, we strive to provide a tool that allows readers to immerse themselves in the beauty of these revered texts. We kindly request your understanding of any shortcomings and encourage you to use the feedback section to report errors, enabling us to promptly rectify issues and continually improve the accuracy and reliability of this invaluable resource.`
const GENERAL_DISCLAIMER=`The Minnatul Aleem team takes full responsibility for any errors or inaccuracies that may occur on this platform.\n
The content on this platform is created through the process of Optical Character Recognition (OCR) of books and periodicals. Due to the limitations of OCR technology, particularly for non-English languages such as Urdu and Arabic, the resulting text is likely to contain spelling mistakes, grammatical errors, and textual inaccuracies.\n
If you wish to quote a book or article, please refer to the original PDF version and do not cite references copied from this search engine.\n
Our intention is to provide a search engine that fuels the passion for exploring the profound wisdom within the writings of the Promised Messiah (as) and the Khulafa (ra). Despite the potential for OCR-based errors, we strive to provide a tool that allows readers to immerse themselves in the beauty of these revered texts. We kindly request your understanding of any shortcomings and encourage you to use the feedback section to report errors, enabling us to promptly rectify issues and continually improve the accuracy and reliability of this invaluable resource.`

const DisclaimerText = ({ index }) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const togglePopup = () => {
   
    setShowPopup(!showPopup);
  };

  const getText = (index) => {
    console.log(index)
    if (index === 'arabic_books') {
      return (
        <p style={{ whiteSpace: 'pre-wrap' ,textAlign:'right', direction: 'rtl'}}>
          <strong>:</strong>
          <strong>إخلاء مسؤولية</strong>{"\n"}
          {ARABIC_BOOKS_DISCLAIMER}
        </p>
      );
    }else if(index==='jamati_books_ar_analyzer') {
      return (
        <p className='urdu-text' style={{ whiteSpace: 'pre-wrap', textAlign:'right', direction: 'rtl' }}>
          <strong>:</strong>
          <strong>دستبرداری</strong>
          {URDU_BOOKS_DISCLAIMER}
        </p>
      );
    }
    else if(index==='new_alfazal') {
      return (
        <p style={{ whiteSpace: 'pre-wrap' }}>
          <strong>Disclaimer:</strong>{"\n"}
          {ALFAZAL_DISCLAIMER}
        </p>
      );
    }
    else {
      return (
        <p style={{ whiteSpace: 'pre-wrap' }}>
          <strong>Disclaimer:</strong>{"\n"}
          {GENERAL_DISCLAIMER}
          
        </p>
      );
    }
  };
  const displaydisclaimer=(index)=>{
    if(index ==='arabic_books'){
      return<p style={{}}>إخلاء مسؤولية:

      يتحمل فريق منة العليم المسؤولية الكاملة عن أي أخطاء أو مغالطات قد تحدث على هذه المنصة.
      
      يتم إنشاء المحتوى على هذه المنصة من خلال عملية التعرف الضوئي على الأحرف (OCR) </p>
    }
    else if(index==='jamati_books_ar_analyzer'){
      return(<p className='urdu-font' style={{whiteSpace:'pre-wrap'}} >دستبرداری:
        منتہ العلیم کی ٹیم اس ویب سائٹ پر ہونے والی کسی بھی قسم کی غلطی کی پوری ذمہ داری لیتی ہے۔
         س ویب سائٹ پر موجود مواد کتابوں اور رسالوں سے آپٹیکل کریکٹر ریکگنیشن کے ذریعے سے۔۔۔۔ 
      </p>)
    }
    else{
      return(<p>
        Disclaimer: The Minnatul Aleem team takes full responsibility for any errors or inaccuracies that may occur on this platform. The content on this platform...
      </p>)
    }

  }

  return (
    <div className="dis-button-with-popup">
      <button className="dis-button" onClick={togglePopup}>
        {displaydisclaimer(index)}
      </button>
      {showPopup && (
        <div className="dis-popup" ref={popupRef}>
          <span className="dis-close" onClick={togglePopup}>
            &times;
          </span>
          {getText(index)}
        </div>
      )}
    </div>
  );
};

export default DisclaimerText;
