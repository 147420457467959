import { searchAction } from "../../store/actions/searchActions";
import Collapsible from "../layout/Collapsible";
import {
  toggleModalAction,
  addCollectionsAction,
  addVolumesAction,
  addAuthorsAction,
  addCollectionAction,
  addAuthorAction,
  addVolAction,
  removeAuthorAction,
  removeCollectionAction,
  removeCollectionsAction,
  removeAuthorsAction,
  removeVolAction,
} from "../../store/actions/filtersActions";
import MyCheckbox from "../layout/Checkbox";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchFilters } from "./FetchFilters";
import { LocalizationContext } from "../../localizationContext";
import langdata from "../../constants/langdata";
const malfozaat = [
  "8sMAs5n2",
  "SLokzcDB",
  "vciRlOFE",
  "wNRJJ7Fl",
  "a8vhDoHr",
  "3LK2Y6U6",
  "BBA7lezu",
  "A0gtc73a",
  "LnAtXH5J",
  "xln1OZY6",
];
const tafsirKabir = [
  "bBCbjtmz",
  "ohSBxUGi",
  "fpH2PvNy",
  "nRB649jf",
  "s10LiDWK",
  "p10zZGK5",
  "BqkQwrbN",
  "RvbtQHcv",
  "UmE1MSLz",
  "65wvVCOI",
];
class ArabicFilters extends Component {
  static contextType = LocalizationContext;

  constructor(props) {
    super(props);
    this.handleAuthorCheckbox = this.handleAuthorCheckbox.bind(this);
    this.handleCollectionCheckbox = this.handleCollectionCheckbox.bind(this);
    this.showCollections = this.showCollections.bind(this);
    this.selectAllCollections = this.selectAllCollections.bind(this);
    this.selectAllAuthors = this.selectAllAuthors.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.selectAllTK = this.selectAllTK.bind(this);
    this.selectAllMalfoozat = this.selectAllMalfoozat.bind(this);
  }

  componentDidMount() {
    fetchFilters("arabicBooks").then((res) => {
      const [authors, collsByAuthors, otherColls] = res;
      this.setState({ filtersResponse: { collsByAuthors, authors } });
    });
  }

  state = {
    selected: {
      authors: [],
      collections: [],
      vols: {},
    },
    filtersResponse: null,
  };

  handleAuthorCheckbox(e) {
    const aid = e.target.id;
    if (e.target.checked) {
      this.props.addAuthor(aid);
    } else {
      this.props.removeAuthor(aid);
    }
  }

  handleCollectionCheckbox(e) {
    const cid = e.target.id;
    if (e.target.checked) {
      this.props.addCollection(cid);
    } else {
      this.props.removeCollection(cid);
    }
  }

  selectAllCollections(e) {
    let newSelected = [];
    const collDivs =
      e.target.parentNode.parentNode.nextSibling.firstChild.childNodes;
    collDivs.forEach((div) => {
      const checkboxID = div.firstChild.firstChild.id;
      const cid = checkboxID;
      newSelected = [...newSelected, cid];
    });

    e.target.checked
      ? this.props.addCollections(newSelected)
      : this.props.removeCollections();
  }
  selectAllTK(e) {
    let newSelected = [];
    let cidArray = tafsirKabir;

    // Select the appropriate array of collIDs based on the Collection type

    // Loop through the collIDs array
    cidArray.forEach((cid) => {
      newSelected = [...newSelected, cid];
    });

    // Update state or dispatch action based on checkbox status
    e.target.checked
      ? this.props.addCollections(newSelected)
      : this.props.removeCollections();
  }
  selectAllMalfoozat(e) {
    let newSelected = [];
    let cidArray = malfozaat;

    // Select the appropriate array of collIDs based on the Collection type

    // Loop through the collIDs array
    cidArray.forEach((cid) => {
      newSelected = [...newSelected, cid];
    });

    // Update state or dispatch action based on checkbox status
    e.target.checked
      ? this.props.addCollections(newSelected)
      : this.props.removeCollections();
  }
  selectAllAuthors(e) {
    let newSelected = [];
    const authDivs = e.target.parentNode.nextSibling.childNodes;

    authDivs.forEach((div) => {
      const aid = div.firstChild.firstChild.id;
      newSelected = [...newSelected, aid];
    });

    if (e.target.checked) {
      this.props.addAuthors(newSelected);
    } else {
      this.props.removeAuthors();
    }
  }

  showCollections(collsByAuthors) {
    const { language } = this.context;
    return Object.entries(collsByAuthors).map((cbaEntry, index) => {
    //this is for that arabic language switcher to assign the id of that author 
      let aname;
      for (let key in langdata.eng.Authors) {
        if (langdata.eng.Authors[key] === cbaEntry[0]) {
          aname = key;
          break;
        }
      }
      const collections = cbaEntry[1];
      let selectAllCheckbox = null;
      let selectAllbox = null;

      // For other authors, default select all checkbox
      selectAllbox = (
        <MyCheckbox
          label={langdata[language]["buttons"]["select all"]}
          onChange={this.selectAllCollections}
        />
      );

      // Determine which select all checkbox to display based on author name
      if (aname === "7m1htVft") {
        selectAllCheckbox = (
          <MyCheckbox
            label={langdata[language]["buttons"]["select all malfozat"]}
            onChange={this.selectAllMalfoozat}
          />
        );
      } else if (aname === "dgR347Hl") {
        selectAllCheckbox = (
          <MyCheckbox
            label={langdata[language]["buttons"]["select all tk"]}
            onChange={this.selectAllTK}
          />
        );
      }

      return (
        <div key={index}>
          <Collapsible
            defaultExpanded={true}
            header={langdata[language]["Authors"][aname]}
            preBody={
              <>
                {selectAllbox}
                <br></br>
                {selectAllCheckbox}
              </>
            }
            body={
              <div className="filters-flex">
                {Object.entries(collections).map((collEntry, index) => {
                  const collName = collEntry[1]["collName"].replaceAll(
                    "_",
                    " "
                  );
                  const collID = collEntry[1]["collID"];
                  return (
                    <div key={index}>
                      <MyCheckbox
                        id={collID}
                        checked={this.props.filters.collections.includes(
                          collID
                        )}
                        label={collName}
                        onChange={this.handleCollectionCheckbox}
                      />
                    </div>
                  );
                })}
              </div>
            }
          />
        </div>
      );
    });
  }

  showAuthors(authors) {
    const { language } = this.context;
    return (
      <div className="authors filters-flex">
        {authors.map((author, index) => {
          const { authorID, authorName } = author;
          return (
            <div key={index}>
              <MyCheckbox
                id={authorID}
                onChange={this.handleAuthorCheckbox}
                checked={this.props.filters.authors.includes(authorID)}
                label={langdata[language]["Authors"][authorID]}
              />
            </div>
          );
        })}
      </div>
    );
  }

  applyFilters() {
    this.props.perfromSearch(
      document.querySelector("#search_field").value,
      this.props.filters
    );
    this.props.toggleModal(false);
  }

  render() {
    const { language } = this.context;

    return this.state.filtersResponse ? (
      <div>
        <legend>{langdata[language]["author"]}</legend>
        <MyCheckbox
          label={langdata[language]["buttons"]["select all"]}
          onChange={this.selectAllAuthors}
        />
        {this.showAuthors(this.state.filtersResponse.authors)}
        <legend>Collections</legend>
        {this.showCollections(this.state.filtersResponse.collsByAuthors)}
        <div className="apply-filters-container">
          <button
            onClick={this.applyFilters}
            className="btn apply-filters-btn margin-auto display-block"
          >
            Apply and Search
          </button>
        </div>
      </div>
    ) : (
      <div>Loading...</div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    perfromSearch: (term, filters) => dispatch(searchAction(term, 0, filters)),
    addAuthors: (authors) => dispatch(addAuthorsAction(authors)),
    addCollections: (colls) => dispatch(addCollectionsAction(colls)),
    addVolumes: (vols) => dispatch(addVolumesAction(vols)),
    addCollection: (coll) => dispatch(addCollectionAction(coll)),
    addAuthor: (auth) => dispatch(addAuthorAction(auth)),
    addVolume: (vno, collID) => dispatch(addVolAction(vno, collID)),
    removeCollection: (coll) => dispatch(removeCollectionAction(coll)),
    removeCollections: () => dispatch(removeCollectionsAction()),
    removeAuthor: (auth) => dispatch(removeAuthorAction(auth)),
    removeAuthors: () => dispatch(removeAuthorsAction()),
    removeVolume: (vno, collID) => dispatch(removeVolAction(vno, collID)),
    toggleModal: (value) => dispatch(toggleModalAction(value)),
  };
};

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArabicFilters);
