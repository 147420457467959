export const COLLECTION_NAMES = {
    1: 'Sair-e-Ruhani',
    2: 'Fatawa-e-Tahir',
    3: 'Malfoozat',
    4: 'Ruhani Khazain',
    5: 'Khatabaat-e-Nur',
    6: 'Majlis Irfan Casettes',
    7: 'Dars-e-Quran',
    8: 'Haqiqat-ud-Dua',
    9: 'Fatawa Musleh Maud Tadween-e-Tarteeb',
    10: 'Fiqhi Masail',
    11: 'Fatawe Musleh Maud',
    12: 'Irshadaat-e-Nur',
    13: 'Fatawe Masih Maud',
    14: 'Ishteharaat',
    15: 'Khutbaat-e-Tahir',
    16: 'Khilafat Ala Minhaj Nabuwah',
    17: 'Fiqh ul Masih',
    18: 'Sciencny Nukaat az Tafsir Kabir',
    19: 'Anwar-ul-Aloom',
    20: 'Khutbaat-e-Nur',
    21: 'Fasl al-Khitab',
    22: 'Maqala Sawalo Jawab 30 Cassettes',
    23: 'Khutbaat-e-Mahmood',
    24: 'Hayat-e-Nur',
    25: 'Dars ul Quran Khalifa Rabeh (rh)',
    26: 'Dars Bukhari by Khalifa Awwal (ra)',
    27: 'Fatawa Khalifa Awwal (ra)',
    28: 'Liqa Ma al-Arab',
    29: 'Dars ul Quran Hazrat Khalifa Khamis (aba)',
    30: 'Ilm-e-Tibb-e-Masih Maud',
    31: 'Dawat ul Ameer',
    32: 'Revelation Rationality Knowledge & Truth (Urdu)',
    33: 'Tadhkirah',
    34: 'Tafsir Hazrat Masih Maud (as)',
    // 35: 'Khutbaat-e-Shura',
    36: 'Khutbat-e-Nasir',
    37: 'Anwar-ul-Quran',
    38: 'Mirqat ul Yaqeen',
    39: 'Maktoobat-e-Ahmad',
    40: 'Tafsir-e-Kabir',
    41: 'Hashiajat of Quran translation by Hazrat Khalifa Rabeh (rh)',
    42: 'Pur Hikmat Nassaih by Khalifa Awwal (ra)',
    43: 'Fazail-ul-Quran',
    44: 'Seeratun-Nabi',
    46: 'Sciency Nukaat az Anwar-ul-Aloom',
    47: 'Roya wa Kashoof Syedna Mahmood',
    48: 'Seeratul Mahdi',
    49: 'Sciency Nukaat',
    50: 'Tibi Maharat',
    51: 'Khutbaat-e-Masroor',
    52: 'Mazameen-e-Bashir',
    53: 'Haqaiqul Furqan',
    60: "Aman Ka Paigham",
    61: "Mazameen-e-Nasir",
    62:"Sabeel-ur-Rasheed",
    63:"Tameer Baitullah",
    64:"Mashale Rah",
    65:"Khitbaat-e-Nasir",
    66:"Tahreek-e-Jadeed",
    67: "Anwaar e Khilafat",
    68: "Aqaid Ahmadiyyat",
    69: "Barkat e Khilafat",
    70: "Das Dalail Hasti Bari Tala",
    71: "Dawat ul Ameer",
    72: "Deebacha Tafseer ul Quran",
    73: "Farmoodat e Musleh Maood",
    74: "Hasti Bari Tala",
    75: "Inqlab e Haqeeqi",
    76: "Islam Ka Iqtasadi Nizam",
    77: "Islam mein ikhtelafat ka aghaz",
    78: "Kalame Mahmood",
    79: "Khilafat ala Minhaj un Nubawt",
    82: "Khilafat Haqa Islamia",
    83: "Khitabat Shura",
    86: "Malaikatullah",
    87: "Mash ale rah",
    88: "Nabiyon Ka Sardar",
    89: "Nizam e Nau",
    90: "Orhani waliyon k liye Phool",
    91: "Prophecy Bible Muhammad Urdu",
    93: "Sabeel ur Rashad",
    94: "Sair e Ruhani",
    95: "Seerat Masih e Maud",
    96: "Seerat un Nabi Alfazl",
    97: "Seerat un Nabi",
    101: "Sochnay Ki Batain",
    102: "Tahreek e Jadeed",
    105: "Tazkaar e Mahdi",
    106: "Zareen Hidayaat",
    107:"Ahmadiyyat ka Paigham",
}



export const OTHER_COLLECTIONS = {
    55: 'Tarikh-e-Ahmadiyyat',
    56: 'Hayat-e-Ahmad',
    57: 'Zikar-e-Habib',
    58: 'Tazkiratul Mahdi',
    59: 'Talqeen-e-Amal',
    103:'Fiqah Ahmadiyya',
    109:'Mazameen-e-Ismail'
}

export const AUTHOR_NAMES = {
    1: 'Hazrat Masih-e-Maud (as)',
    2: 'Hazrat Khalifatul Masih I (ra)',
    3: 'Hazrat Musleh-e-Maud (ra)',
    4: 'Hazrat Khalifatul Masih III (rh)',
    5: 'Hazrat Khalifatul Masih IV (rh)',
    6: 'Hazrat Khalifa-e-Waqt (aba)',
    7: 'Hazrat Mirza Bashir Ahmad Sahib (ra)',
    15: 'Maulana Dost Muhammad Shahid'
}

export const COLLECTIONS_BY_AUTHOR = {
    1: [3, 4, 13, 14, 17, 30, 33, 34, 39],
    2: [5, 12, 53, 20, 21, 24, 26, 27, 38, 42],
    3: [ 9, 11, 19, 23, 40, 43, 44, 46, 47, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 82, 83, 86, 87, 88, 89, 90, 91, 93, 94, 95, 96, 97, 101, 102, 105, 106],
    4: [36, 37,60,61,62,63,64,65,66],
    5: [2, 6, 15, 22, 25, 32, 41],
    6: [29, 51],
    7: [48, 52, ]
}

export const VOLUMES = { 
    // collection_id: total_volumes
    4: 23,
    3: 10,
    15: 16,
    19: 26,
    51: 19,
    23: 39,
    35: 3,
    36: 10,
    37: 3,
    40: 15,
    52: 4,
    53: 4,
    55: 28,
    65:2,
    106:4,
    102:3,
    83:3,
    79:4,
    97:3
}


export const topics = { // collID.vno.topic
    23: {
        16: [
            {id: 1, 'topic': '04 جنوری, 1935	نئے سال کیلئے جماعت احمدیہ کا پروگرام'}, 
            {id: 2, 'topic': ' 11 جنوری, 1935	خدمتِ اسلام کیلئے جماعت کا ہر فرد اپنی زندگی وقف کر دے'},
            {id: 3, 'topic': ' 18 جنوری, 1935	شریعت، قانون، اخلاق اور دیانت کے خلاف کوئی بات نہ کی جائے'},
        ],
        2: []
    }
}

