export const toggleModalAction = (value) => {
    return { type: 'TOGGLE_MODAL', value }
}

// add selection
export const addCollectionAction = (collection) => { //list
    return { type: 'ADD_COLLECTION', collection }
}

// remove selection
export const removeCollectionAction = (collection) => {
    return { type: 'REMOVE_COLLECTION', collection }
}

export const addAuthorAction = (author) => {
    return { type: 'ADD_AUTHOR', author }
}

export const removeAuthorAction = (author) => {
    return { type: 'REMOVE_AUTHOR', author }
}

export const addVolAction = (vno) => {
    return { type: 'ADD_VOLUME', vno }
}

export const removeVolAction = (vno) => {
    return { type: 'REMOVE_VOLUME', vno }
}

export const addVolumesAction = (volumes) => {
    return { type: 'ADD_VOLUMES', volumes }
}

export const addAuthorsAction = (authors) => {
    return {type: 'ADD_AUTHORS', authors}
}

export const addCollectionsAction = (collections) => {
    return {type: 'ADD_COLLECTIONS', collections}
}

export const removeCollectionsAction = () => {
    return {type: 'REMOVE_COLLECTIONS'}
}

export const removeAuthorsAction = () => {
    return {type: 'REMOVE_AUTHORS'}
}

export const replaceAllAction = (newFilters) => {
    return {type: 'REPLACE_ALL', data: newFilters}
}

export const addYearsAction = (years) => {
    return {type: 'ADD_YEARS', years}
}

export const changeTabAction = tab => {
    return {type: 'CHANGE_TAB', tab}
}

export const addYearAction = (year) => {
    return {type: 'ADD_YEAR', year}
}

export const removeYearAction = year => {
    return {type: 'REMOVE_YEAR', year}
}

export const removeYearsAction = () => {
    return {type: 'REMOVE_YEARS'}
}

export const changeQtypeAction = value => {
    return {type: 'CHANGE_QTYPE', value}
}
export const changeSortOrderAction = value => {
    return {type: 'CHANGE_SORTORDER', value}
}
export const addMonthAction = month => {
    return { type: 'ADD_MONTH', month }
}

export const removeMonthAction = month => {
    return { type: 'REMOVE_MONTH', month }
}
export const addMonthsAction = month => {
    return { type: 'ADD_MONTHS', month }
}

export const removeMonthsAction = month => {
    return { type: 'REMOVE_MONTHS', month }
}
export const addChapterAction = chapter => {
    return { type: 'ADD_CHAPTER', chapter }
}

export const removeChapterAction = chapter => {
    return { type: 'REMOVE_CHAPTER', chapter}
}