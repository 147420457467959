import React, { Component } from "react";
import { connect } from "react-redux";
import {
  toggleDetailsModalAction,
  clearDetailsAction,
} from "../../store/actions/resultDetailsActions";
import BaseModal from "../layout/BaseModal";
import MySpinner from "../layout/MySpinner";
import {
  itemInfo,
  getTextClass,
  showLivePDFButton,
  showPDFButton,
} from "./ResultFunctions";
import { HOLY_QURAN_INDEX } from "../../backendInfo";
import DisclaimerText from "../layout/disclaimerText";

class ResultDetails extends Component {
  holyQuranText = (hit) => {
    let text = "";
    let hFields = [];
    if (hit.highlight) {
      hFields = Object.keys(hit.highlight);
    }
    Object.entries(hit._source).forEach((field) => {
      const fieldName = field[0];
      if (
        !fieldName.includes("_info") &&
        !fieldName.includes("_lang") &&
        fieldName !== "chapter" &&
        fieldName !== "verse" &&
        fieldName !== "url"
      ) {
        if (fieldName !== "arabic" && fieldName !== "arabic_no_diacritics") {
          const infoFieldName = fieldName + "_info";
          const fieldInfo = hit._source[infoFieldName];
          if (hit._source[fieldName])
            text += `<p style='text-align:center;text-align-last:left !important'>${fieldInfo}</p>`;
        }
        if (hFields.includes(fieldName)) {
          text += `<p>${hit.highlight[fieldName]}</p>`;
        } else if (hit._source[fieldName]) {
          const langFieldName = fieldName + "_lang";
          const style =
            hit._source[langFieldName] === "urdu"
              ? "text-align:right;text-align-last:right !important"
              : "text-align:left;text-align-last:left !important";
          text += `<p style='${style}'>${hit._source[fieldName]}</p>`;
        }
      }
    });
    return text;
  };

  state = {
    text: "",
  };

  render() {
    const { details, isOpen, closeDetails, clearDetails } = this.props;
    if (details) {
      const content = details.map((hit) => {
        let text = "";
        if (hit._index === HOLY_QURAN_INDEX) {
          // text = this.holyQuranText(hit)
          this.setState({ text: this.state.text + this.holyQuranText(hit) });
        } else if (hit.highlight) text = hit.highlight["text"][0];
        //text = hit._source.text
        else text = text.replaceAll("\n", "<br>");
        return (
          <div key={hit._id}>
            <p
              className={getTextClass(hit._source.lang)}
              dangerouslySetInnerHTML={{ __html: this.state.text }}
            ></p>
            <p className="width-content margin-auto">
              {itemInfo(hit._source, hit._index)}
            </p>
          </div>
        );
      });

      let title = itemInfo(details[1]._source, details[1]._index);

      return (
        <BaseModal
          content={<div>{content}</div>}
          title={
            <div>
              <span>{title}</span>

              {this.showPDFButton(details[1]) && (
                <a
                  id="openInBookBtn"
                  className="btn btn-small"
                  href={details[1]._source.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open page in book
                </a>
              )}
              {this.showLivePDFButton(details[1]) && (
                <a
                  id="openInPDFBtn"
                  className="btn btn-small"
                  href={details[1]._source.url_live}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open page on Al-Islam
                </a>
              )}
            </div>
          }
          titlePosition="bottom"
          isOpen={isOpen}
          handleClose={() => {
            closeDetails();
            clearDetails();
          }}
          contentStyle={{ marginTop: "40px" }}
        />
      );
    } else {
      return (
        <BaseModal content={<MySpinner display={true} />} isOpen={isOpen} />
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeDetails: () => dispatch(toggleDetailsModalAction(false)),
    clearDetails: () => dispatch(clearDetailsAction()),
  };
};

export default connect(null, mapDispatchToProps)(ResultDetails);
