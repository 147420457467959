import {collectionList} from './BooksWithButton.js';
import {HISTORY, URDU_BOOKS_INDEX, ROR_INDEX, ALFAZAL_INDEX, ENGLISH_BOOKS_INDEX, ARABIC_BOOKS_INDEX, HOLY_QURAN_INDEX, ASK_ISLAM_INDEX, DARSUL_QURAN_INDEX, ALBADAR_INDEX, ALHAKAM_INDEX,MUSLIM_SUNRISE_INDEX,ALL_TABS_INDEX,FRIDAY_SERMON,AK_DIARIES, GERMAN_BOOKS } from '../../backendInfo.js';


export const detailParams = (source, tab, qtype) => {
    let localSource = JSON.parse(JSON.stringify(source))
    // remove unnecessary fields
    delete localSource['text']
    delete localSource['text_diacritics']
    delete localSource['author']
    delete localSource['author_id']
    delete localSource['author_priority']
    delete localSource['summary']
    delete localSource['description']
    // delete localSource['collection']
    delete localSource['collection_priority']
    delete localSource['desc']
    // delete localSource['lang']
    delete localSource['col1']
    delete localSource['col2']
    delete localSource['col3']
    delete localSource['col4']
    delete localSource['col5']
    delete localSource['col6']
    delete localSource['col7']
    delete localSource['col8']
    delete localSource['col9']

    localSource.tab = tab
    localSource.qtype = qtype
    localSource.term = document.querySelector("#search_field").value
    
    return localSource
}

export const itemInfo = (source, indexName) => {
    if(source.ref_text && source.collection_id !==19 )
        return source.ref_text
    
    let info = ''
    if(indexName === URDU_BOOKS_INDEX){
        if(source.collection)
            info += source.collection
        if(source.vol_no && source.vol_no !== null)
            info += ` Volume ${source.vol_no}`
        if(source.other)
            info += ` (${source.other})`
        if(source.year)
            info += ` (${source.year})`
        // if(source.collection_id === 4) //rk
        //     info += ` (${source.book_name})`    
        if(source.ref_text && source.collection_id ==19 ){
            info += `${source.ref_text}`
        }
        if(source.khutba_no) // khm
            info += ` \nKhutba #${source.khutba_no}`
        if(source.khutba_title)
            info += ` (${source.khutba_title})`
        // if(source.collection_id === 55) //tka
        //     info += ` (PDF Page no. ${source.pdf_pno})`
        else if(source.page_no)
            info += ` Page ${source.page_no}`
        else if(source.para_no)
            info += ` Paragraph ${source.para_no}`
    }
    else if(indexName === ROR_INDEX){
        info += 'Review of Religions'
        if(source.month)
            info += ` ${source.month}`
        if(source.year)
            info += ` ${source.year}`
        if(source.page_no)
            info += ` Page ${source.page_no}`
        if(source.page_no === 0)
            info += ` Table of Contents/Index Page`
    }
    else if(indexName === ALFAZAL_INDEX){
        info += source.ref_text
    }
    else if(indexName === ENGLISH_BOOKS_INDEX || indexName === ARABIC_BOOKS_INDEX){
        if(source.collection)
            info += source.collection
        if(source.vol_no)
            info += ` Volume ${source.vol_no}`
        if(source.book_name)
            info += ` ${source.book_name}`
        if(source.page_no)
            info += ` Page ${source.page_no}`
        if(source.page_no === 0)
            info += ' Preface/Foreword'
    }
    else if(indexName === HOLY_QURAN_INDEX){
        info += `Chapter ${source.chapter}`
        info += ` Verse ${source.verse}`
    }
    else if(indexName === ASK_ISLAM_INDEX){
        info += `Question: ${source.question}`
        info += ` - (${source.date})`
    }
    else if(indexName === DARSUL_QURAN_INDEX){
        info += `${source.title}`
        info += ` - (${source.date})`
    }
    else if(indexName === ALBADAR_INDEX){
        info += `Al-Badar Shumara ${source.shumara}`
        info += ` - (${source.year})`
    }
    else if(indexName === ALHAKAM_INDEX){
        info += `Al-Hakam Shumara ${source.shumara}`
        info += ` - (${source.month} ${source.ate}, ${source.year})`
    }
    else if(indexName===MUSLIM_SUNRISE_INDEX){
        // const maxLength = 100;
        // const truncatedTitle = source.title.length > maxLength
        // ? source.title.slice(0, maxLength) + "..."
        // : source.title;
        // info += `Title: ${truncatedTitle}`;
        info+=`${source.title} ${source.year} Issue ${source.issue_no},Page ${source.page_no} `
    }
    else if(indexName === FRIDAY_SERMON){
        info += `${source.title} `
        info += ` ${source.date}`

    }
    else if(indexName === AK_DIARIES){
        info += `Abid Khan Sahib's diary ${source.title} `
        info += `Page ${source.page_no}`
    }
    else if(indexName === HISTORY){
        info += `${source.title} `
        info += `Page ${source.page_no}`
    }
   
    else if(indexName === GERMAN_BOOKS){
        info += `${source.title} `
        info += `Page ${source.page_no}`
    }
    return info
}

export const getDetailItemInfoToggle = (indexName) => {
    const isShow = {
        URDU_BOOKS_INDEX : true,
        ENGLISH_BOOKS_INDEX : true,
        ARABIC_BOOKS_INDEX: true,
        ROR_INDEX : true,
        ALFAZAL_INDEX : true,
        HOLY_QURAN_INDEX : true,
        ASK_ISLAM_INDEX: false, 
        DARSUL_QURAN_INDEX : false,
        ALBADAR_INDEX : false,
        ALHAKAM_INDEX : false,
        MUSLIM_SUNRISE_INDEX:false,
        FRIDAY_SERMON:false,
        AK_DIARIES:false,

    }

    return isShow[indexName]
}


export const getTextClass = lang => {
    if (lang === "en" || lang === "english" ){
        return "text-align-left"
    }
    if (lang === "de" || lang === "german" ){
        return "text-align-left"
    }
    else if (lang === "ar" || lang === "arabic" ){
        return "arabic-text"
    }
    else { // urdu data doesnt have lang field
        return "urdu-text"
    }
}

export const showLivePDFButton = (source) => {
    if(source.collection_id === 4)
        return true
    else if (source.collection_id === 'hTee3Wibn7')
        return true
    return false
}

export const showPDFButton = (source, index) => {
    if (index === ENGLISH_BOOKS_INDEX){
        return true
    }
    else if (index === ARABIC_BOOKS_INDEX){
        return true
    }
    else if (
        index === URDU_BOOKS_INDEX
        && (collectionList.includes(source.collection_id))){
        return true
    }
    else if (index === ROR_INDEX){
        return true
    }
    else if (index === ALFAZAL_INDEX){
        return true
    }
    else if (index=== AK_DIARIES){
        return true
    }
    else if (index=== HISTORY){
        return true
    }
    else if (index=== GERMAN_BOOKS){
        return true
    }
    
    return false
}

export const showOpenLinkButton = (queryParams,index) => {
    if (index === ASK_ISLAM_INDEX && queryParams.ask_islam_link){
        return true
    }
    else if (index=== FRIDAY_SERMON ){
        return true
    }
    return false
}

export const showYoutubeLinkButton = (queryParams,index) => {
    if (index === DARSUL_QURAN_INDEX && queryParams.youtube_link){
        return true
    }
    return false
}

export const showAudioBlock = (index) => {
    if (index === ASK_ISLAM_INDEX){
        return true
    }
    return false
}


export const signedUrl = (queryParams) => {
    return (
        [URDU_BOOKS_INDEX, HISTORY, GERMAN_BOOKS ].includes(queryParams.tab) &&
        'gcp_book_name' in queryParams
    );
};