import React, { Suspense, useState,useContext } from 'react';
import axios from 'axios'; 
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Cookies from 'js-cookie'; 
import { backendUrl,saveFeedbackPath } from '../backendInfo';
import Alert from '@material-ui/lab/Alert';
import { red } from '@material-ui/core/colors';
import langdata from '../constants/langdata';
import { LocalizationContext } from '../localizationContext';

const FeedbackForm = (params) => {
    const [open, setOpen] = useState(false);
    const [subject, setSubject] = useState('');
    const [feedback, setFeedback] = useState('');
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState('success');
    const { language } = useContext(LocalizationContext);

    const handleClick = () => {
        setOpen(true); 
        setMessage(null); // Reset message when opening the form
    };

    const handleClose = () => {
        setOpen(false);
        setMessage(null); // Reset message when closing the form
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const access = Cookies.get('token');
        if (String(access) !== "null") {
            try {
                await axios.post(
                    `${backendUrl}${saveFeedbackPath}`,
                    { subject, feedback ,params},
                    { headers: { Authorization: `Bearer ${access}` } }
                );
                setSubject('');
                setFeedback('');
                setMessageType('success');
                setMessage('Feedback successfully sent. Thank you!');
            } catch (error) {
                console.error('Error submitting feedback:', error);
                setMessageType('error');
                setMessage('The server is a bit overwhelmed. Please try again in a bit.');
            }
        } else {
            // console.error('No access token found.');
            setMessageType('error');
            setMessage('No access token found.');
        }
    };

    return (
        <>
            <Dialog open={open} fullWidth={true} maxWidth={'sm'} scroll='paper'>
                <MuiDialogTitle disableTypography>
                <Typography variant="h6">{langdata[localStorage.getItem('lang-pref') || 'eng']['buttons']['Feedback']}</Typography>
                    <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', top: '8px', right: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <form onSubmit={handleSubmit} style={{ padding: '16px' }}>
                <TextField
                        fullWidth
                        variant="outlined"
                        label={langdata[language]["buttons"]["subject"]}
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        margin="normal"
                        required
                        multiline
                        
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label={langdata[language]['buttons']['Feedback']}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        margin="normal"
                        required
                        multiline
                        rows={6}
                        className='.feed-feedback'
                    />
                    {message && (
                        <Alert severity={messageType} style={{ marginTop: '16px' }}>
                            {message}
                        </Alert>
                    )}
                    <div style={{textAlign:'center'}}>
                    <button  className='feed-submit-btn' type="submit" variant="contained" >
                        {langdata[language]["buttons"]["submit"]}
                    </button>
                    </div>
                </form>
            </Dialog>
            <div style={{ maxWidth: '100%' }}>
                <Link
                    component="button"
                    onClick={handleClick}
                    style={{
                        alignItems:'center',
                        display: 'grid', gridTemplateColumns: '0.5fr 0.5fr',
                        color: 'lightgray',
                       
                    }}
                >
                    <FeedbackIcon fontSize="large"/><p className='icon-link'>{langdata[language]['buttons']['Feedback']}</p>
                </Link>
            </div>
        </>
    );
}

export default FeedbackForm;
