import React, { Component } from "react";
import { connect } from "react-redux";
import { changeQtypeAction } from "../../store/actions/filtersActions";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import langdata from "../../constants/langdata";

class QuerySelection extends Component {
  state = {
    queryTextToTab: this.getQueryTextToTab(),
  };

  constructor(props) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  getQueryTextToTab() {
    const langPref = localStorage.getItem("lang-pref") || "eng"; // Use English as default language if not set
    if (langdata.hasOwnProperty(langPref)) {
      return langdata[langPref]["queryTextToTab"];
    } else {
      // Provide a default value or handle error
      return langdata["eng"]["queryTextToTab"];
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.updateBtnTextToTab(this.props.lang);
    }
  }
  updateBtnTextToTab(lang) {
    const langPref = localStorage.getItem("lang-pref") || "eng";
    if (langdata.hasOwnProperty(langPref)) {
      const btnTextToTab = langdata[langPref]["queryTextToTab"];
      this.setState({ queryTextToTab: btnTextToTab });
    } else {
      // Handle the case where langPref is not a valid key in langdata
      const btnTextToTab = langdata["eng"]["queryTextToTab"];
      this.setState({ queryTextToTab: btnTextToTab });
    }
  }

  onButtonClick(e) {
    const btnText = e.target.innerText.trim();
    let queryType;

    if (
      this.state.queryTextToTab &&
      this.state.queryTextToTab.hasOwnProperty(btnText)
    ) {
      switch (this.state.queryTextToTab[btnText]) {
        case "Hybrid Match":
          queryType = "1";
          break;
        case "Phrase Match":
          queryType = "2";
          break;
        case "Term Match (OR)":
          queryType = "3";
          break;
        case "Term Match (AND)":
          queryType = "4";
          break;
        default:
          queryType = "1";
      }
    }
    if (queryType) {
      let btns = Array.from(document.getElementsByClassName("q-select-btns"));
      btns.forEach((b) => {
        if (String(b.id) === queryType) {
          b.classList.toggle("pressed-btn");
        } else {
          b.classList.remove("pressed-btn");
        }
      });

      this.props.changeQtype(queryType);
    }
  }

  render() {
    return (
      <ButtonGroup
        color="default"
        disableRipple={true}
        disableFocusRipple={true}
        disableElevation
        onClick={this.onButtonClick}
        variant="contained"
        aria-label="contained primary button group"
      >
        {Object.keys(this.state.queryTextToTab).map((key, index) => {
          const queryType = (index + 1).toString();
          return (
            <Button
              key={index}
              className={`q-select-btns ${index === 0 ? "pressed-btn" : ""}`}
              id={queryType}
            >
              {key}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
    lang: state.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeQtype: (value) => dispatch(changeQtypeAction(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuerySelection);
