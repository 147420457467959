import React, {Component} from 'react';
import Switch from './Switch';


class ThemeToggler extends Component {
    state = {darkTheme: false}

    componentDidMount(){
        this.setState({
            darkTheme: localStorage.getItem('theme-pref') === 'dark'
        })
    }

    toggleTheme = (e) => {
        if(e.target.checked){
            this.setState({darkTheme: true})
            localStorage.setItem('theme-pref', 'dark')
            document.body.className += " dark-mode"
        }
        else{
            this.setState({darkTheme: false})
            localStorage.setItem('theme-pref', 'light')
            var classes = document.body.className.split(' ')
            var i = classes.indexOf('dark-mode')
            if(i >= 0){
                classes.splice(i, 1)
                document.body.className = classes.join(' ')
            }
        }
    }

    render(){ 
        return <Switch label={"Dark mode"} checked={this.state.darkTheme} onChange={this.toggleTheme}/>
    }
    
}

export default ThemeToggler;