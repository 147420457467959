import React, { Component } from 'react'

function startTimer(duration, element) {
    var timer = duration, minutes, seconds;
    setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        element.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
            clearInterval(this);
        }
    }, 1000);
}


class Timer extends Component{
    componentDidMount(){
        startTimer(this.props.duration, document.getElementById(this.props.id))
    }

    render(){
        return <span id={this.props.id}></span>
    }
}

export default Timer;