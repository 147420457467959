const langdata = {
  eng: {
    btnTextToTab: {
      "ALL TABS": "all_indices",
      "THE HOLY QURAN": "holyQuran",
      "URDU JAMAAT BOOKS": "books",
      "ENGLISH JAMAAT BOOKS": "engBooks",
      "ARABIC BOOKS": "arabicBooks",
      "GERMAN BOOKS":"germanBooks",
      "AL-FAZL": "alfazal",
      "REVIEW OF RELIGIONS": "ror",
      "ASK ISLAM": "askislam",
      "DARSUL-QURAN": "darsulQuran",
      "AL-BADAR": "albadar",
      "AL-HAKAM": "alhakam",
      "MUSLIM SUNRISE": "muslimSunrise",
      "FRIDAY SERMON": "fridaySermon",
      'AK\'S DIARY': "akDiaries",
      "HISTORY":"history"
    },
    queryTextToTab: {
      "HYBRID MATCH": "Hybrid Match",
      "PHRASE MATCH":  "Phrase Match",
      "TERM MATCH (OR)": "Term Match (OR)",
      "TERM MATCH (AND)":  "Term Match (AND)",
    },
    sortTextToTab: {
      "CHRONOLOGICAL ORDER": "chronological",
      "RELEVANCE ORDER": "relevance",
    },
    buttons: {
      "Search": "Search",
      "Advanced Filters": "Advanced Filters",
      "Back": "Back",
      "Feedback": "Feedback",
      "Bookmarks": "Bookmarks",
      "About Us": "About Us",
      "Settings": "Settings",
      "Next Page": "Next Page",
      "Previous Page": "Previous Page",
      "Select all": "Select all",
      "Open Youtube link": "Open Youtube link",
      "Open Original Link": "Open Original Link",
      "Open page in Book": "Open Page in Book",
      "Next": "Next",
      'Previous': "Previous",
      "Paragraph": "Paragraph",
      "Page": "Page",
      "submit": "Submit",
      "subject": "Subject",
      "select all": "Select All",
      "select all malfozat": "Select all Malfoozat volumes",
      "select all tk": "Select all Tafsir kabir volumes",
    },
    filtersTitle:{
      
        'books':    'Jamati Books',
        'ror':      'Review of Religions',
        'alfazal':  'Al-Fazal',
        'engBooks': 'English Jamaat Books',
        'arabicBooks': 'Arabic Books',
        'holyQuran': 'The Holy Quran',
        'fridaySermon': 'Friday Sermon'
  
    },
    Authors: {
      "7m1htVft": "Hazrat Masih Maud (as)",
      "TmSGrSKz": "Written by Scholars",
      "toFJPQP6": "Hazrat Khalifa-e-Waqt (aba)",
      "dgR347Hl": "Hazrat Musleh-e-Maud (ra)",
      "d4x7soWD": "Hazrat Khalifatul Masih IV (Rahmat Allah Alayhi)",
    },
    Author:'Authors',
    "disclaimer": "",
  },
  ar: {
    btnTextToTab: {
      "كل الحقول": "all_indices",
      "القرآن الكريم": "holyQuran",
      "كتب الجماعة بالأردية": "books",
      "كتب الجماعة بالإنجليزية": "engBooks",
      "كتب الجماعة بالعربية": "arabicBooks",
      "الكتب الألمانية": "germanBooks",
      "جريدة الفضل (أردية)": "alfazal",
      "مجلة مقارنة الأديان (إنجليزية)": "ror",
      "اسأل الإسلام (إنجليزية)": "askislam",
      "درس القرآن (إنجليزية)": "darsulQuran",
      "جريدة البدر (أردية)": "albadar",
      "جريدة الحكم (أردية)": "alhakam",
      "فجر المسلمين (إنجليزية)": "muslimSunrise",
      "خطب الجمعة (أردية وإنجليزية)": "fridaySermon",
      "يوميات الخليفة (إنجليزية)": "akDiaries",
       "التاريخ": "history"
    },
    queryTextToTab: {

      "تطابق عامّ": "Hybrid Match",
      "تطابق كلّي": "Phrase Match",
      "تطابق العبارات (أو)": "Term Match (OR)",
    "تطابق العبارات (و)": "Term Match (AND)",
    },
    sortTextToTab: {
      "الترتيب الزمني": "chronological",
      "ترتيب الصلة": "relevance",
    },
    buttons: {
      "Search": "بحث",
      "Advanced Filters": "خيارات متقدمة",
      "Back": "رجوع",
      "Feedback": "ملاحظات",
      "Bookmarks": "العلامات المرجعية",
      "About Us": "معلومات عنا",
      "Settings": "الإعدادات",
      "Next Page": "الصفحة التالية",
      "Previous Page": "الصفحة السابقة",
      "Select all": "تحديد الكل",
      "Open Youtube link": "فتح رابط يوتيوب",
      "Open Original Link": "فتح الرابط الأصلي",
      "Open page in Book": "فتح الصفحة في الكتاب",
      "Next": "التالي",
      "Previous": "السابق",
      "Paragraph": "فقرة",
      "Page": "صفحة",
      "submit": "قدم",
      "subject": "موضوع",
      "select all": "تحديد الكل",
      "select all malfozat": "تحديد جميع مجلدات الملفوظات",
      "select all tk": "تحديد جميع مجلدات تفسير الكبير",
    },
    filtersTitle :{
      'books':    'كتب الجماعة بالأردية',
      'ror':      'مجلة مقارنة الأديان (إنجليزية)',
      'alfazal':  'جريدة الفضل (أردية)',
      'engBooks': 'كتب الجماعة بالإنجليزية',
      'arabicBooks': 'كتب الجماعة بالعربية',
      'holyQuran': 'القرآن الكريم',
      'fridaySermon': 'خطب الجمعة (أردية وإنجليزية)'
  },
    Authors: {
      "7m1htVft": "حضرة المسيح الموعود عليه السلام",
      "TmSGrSKz": "علماء الجماعة",
      "toFJPQP6": "حضرة خليفة الوقت أيده الله",
      "dgR347Hl": "حضرة المصلح الموعود رضي الله عنه",
      "d4x7soWD": "حضرة خليفة المسيح الرابع رحمه الله",
    },
    Author:'الكتّاب',

    disclaimer: "",
  },
};

export default langdata;
