import React, { createContext, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'; // Import useLocation

export const LocalizationContext = createContext();

export const LocalizationProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('lang-pref') || "eng");
  const location = useLocation(); 

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const lang = urlParams.get('lang');
    if (lang && lang !== language) {
      changeLanguage(lang);
    }
  }, [location]); // React to changes in location
  
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('lang-pref', newLanguage);
  };

  return (
    <LocalizationContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LocalizationContext.Provider>
  );
};