import logo from '../../static/logo-golden.png';
import React from 'react';


const Logo = ({size}) => {
    if(size === "large"){
        return (
            <div>
                <img className="logo-large" src={logo} alt="app logo"/>
            </div>
        )
    }
    else{
        return (
            <div className="width-content margin-auto">
                <img className="logo-small" src={logo} alt="app logo"/>
            </div>
        )
    }
}

export default Logo;


                