const waitingTime = (noOfResults) => {
    const m = 0.6093220338983051
    const b = -6.69491525423725
    return m*noOfResults + b + 45 // to be on the safe side
}

export const addPDFAction = (term, noOfResults) => {
    const duration = waitingTime(noOfResults)
    
    return {type: 'ADD_PENDING', data: {term, duration}}
}