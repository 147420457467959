import MediaQuery from 'react-responsive';
import React, { Component } from 'react';
import ResultItem from './ResultItem';
import Export from './Export';


class ResultList extends Component{

    listClassName = {
        books: "container result-list direction-rtl padding-right-3p",
        ror: "container result-list padding-left-3p",
        alfazal: "container result-list direction-rtl padding-right-3p",
        engBooks: "container result-list padding-left-3p",
        holyQuran: 'container result-list direction-rtl padding-right-3p',
        arabicBooks: 'container result-list direction-rtl padding-right-3p',
        askislam: 'container result-list padding-left-3p',
        darsulQuran: 'container result-list padding-left-3p',
        albadar: 'container result-list padding-left-3p',
        alhakam: 'container result-list padding-left-3p',
        muslimSunrise: "container result-list padding-left-3p",
        all_indices: "container result-list padding-left-3p",
        fridaySermon: "container result-list padding-left-3p",
        akDiaries: "container result-list padding-left-3p",
        history:"container result-list padding-left-3p",
       
        germanBooks:"container result-list padding-left-3p",



    }
    
    render(){
        const { results, totalResults, tab } = this.props;
        if (results.length) {
            return (
            <div>
                <span className="margin-top-5 display-block width-content margin-auto">Results Found: {totalResults === 10000 ? '10000+' : totalResults}</span>
                <MediaQuery minWidth={993}>
                    <Export />
                </MediaQuery>
                <ol className={this.listClassName[tab]}>
                {
                    results.map(resultItem => {
                            return (
                                <li key={resultItem._id}> <ResultItem resultItem={resultItem}/> </li>
                                )
                            }
                        )
                }
                </ol>
            </div>
            )
        }
        else{
            return <div className="result-list"></div> //scroll function is looking for this class 
        }
    }
}


export default ResultList;
