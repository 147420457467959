import React, { Component } from "react";
import { connect } from "react-redux";
import {
  changeTabAction,
  changeQtypeAction,
  replaceAllAction,
} from "../../store/actions/filtersActions";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import MediaQuery from "react-responsive";
import SplitButton from "../layout/SplitButton";
import langdata from "../../constants/langdata";
import axios from "axios"; // Add axios for making API requests
import { customtab,backendUrl } from "../../backendInfo";
import Cookies from 'js-cookie'; 

class CategorySelection extends Component {
  state = {
    selectedBtnId: "all_indices",
    btnTextToTab: {},
    langPref: localStorage.getItem("lang-pref"),
    backendIndexes: {}, // State to store backend indexes
  };

  constructor(props) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  componentDidMount() {
    this.fetchBackendIndexes(); // Fetch indexes from the backend when the component mounts
  }

  componentDidUpdate(prevProps, prevState) {
    if (localStorage.getItem("lang-pref") !== prevState.langPref) {
      this.updateBtnTextToTab();
    }
  }

  async fetchBackendIndexes() {
    try {
      const access = Cookies.get('token'); // Get the token from localStorage
      const response = await axios.get(`${backendUrl}${customtab}`, {
        headers: {
          'Authorization': `Bearer ${access}` // Add the token to the request headers
        }
      });
      this.setState({ backendIndexes: response.data }, () => {
        this.updateBtnTextToTab();
      });
    } catch (error) {
      console.error("Failed to fetch indexes from the backend:", error);
    }
  }
  updateBtnTextToTab() {
    let langPref = localStorage.getItem("lang-pref");
    if (!langPref) {
      langPref = "eng";
      localStorage.setItem("lang-pref", "eng");
    }
    if (langdata[langPref]) {
      const btnTextToTab = langdata[langPref]["btnTextToTab"];
      
      // Filter out the buttons that do not exist in the backend indexes
      const filteredBtnTextToTab = Object.keys(btnTextToTab).reduce(
        (filtered, key) => {
          if (this.state.backendIndexes.includes(btnTextToTab[key])) {
            filtered[key] = btnTextToTab[key];
          }
          return filtered;
        },
        {}
      );

      this.setState({ btnTextToTab: filteredBtnTextToTab, langPref: langPref });
    } else {
      console.error(`No language preference found for ${langPref}`);
    }
  }
  onButtonClick(e) {
    const btnText = e.target.innerText.trim();
    const tab = this.state.btnTextToTab[btnText];

    this.setState({ selectedBtnId: tab });
    this.props.changeTab(tab);
    this.props.replaceAll({
      collections: [],
      authors: [],
      volumes: [],
      years: [],
    });
  }

  render() {
    return (
      <>
        <MediaQuery maxWidth={992}>
          <SplitButton
            onMenuItemClick={this.onButtonClick}
            options={Object.keys(this.state.btnTextToTab)}
          />
        </MediaQuery>
        <MediaQuery minWidth={993}>
          <ButtonGroup
            variant="contained"
            color="default"
            aria-label="contained primary button group"
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {Object.keys(this.state.btnTextToTab).map((key) => (
                <Button
                  key={key}
                  className={
                    this.state.btnTextToTab[key] === this.state.selectedBtnId
                      ? "pressed-btn cat-btns"
                      : "cat-btns "
                  }
                  id={this.state.btnTextToTab[key]}
                  variant="contained"
                  style={{
                    width: "calc(100% / 8)", // Adjust width to fit 8 buttons per row
                    borderRadius: 0, // Set border radius to 0
                    margin: 0, // Remove default margin
                    padding: "10px", // Add padding for spacing between buttons
                    fontFamily:
                      this.props.lang === "ar" ||
                      localStorage.getItem("lang-pref") === "ar"
                        ? '"NotoNaskhFont"'
                        : '"Roboto", "Helvetica", "Arial", sans-serif', // Add fontFamily here
                  }}
                  onClick={this.onButtonClick}
                >
                  {key}
                </Button>
              ))}
            </div>
          </ButtonGroup>
        </MediaQuery>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
  lang: state.lang,
});

const mapDispatchToProps = (dispatch) => ({
  changeQtype: (value) => dispatch(changeQtypeAction(value)),
  changeTab: (value) => dispatch(changeTabAction(value)),
  replaceAll: (newFilters) => dispatch(replaceAllAction(newFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelection);
