import React, { Component } from "react";
import { connect } from "react-redux";
import MyCheckbox from "../layout/Checkbox";
import { searchAction } from "../../store/actions/searchActions";
import {
  toggleModalAction,
  addYearAction,
  addYearsAction,
  removeYearAction,
  removeYearsAction,
  addMonthAction,
  removeMonthAction,
  addCollectionAction,
  removeCollectionAction,
} from "../../store/actions/filtersActions";
import Collapsible from "../layout/Collapsible";

const range = (start, end) =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start);

class HIstoryFilter extends Component {
  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  constructor(props) {
    super(props);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleYearCheckbox = this.handleYearCheckbox.bind(this);
    this.selectAllYears = this.selectAllYears.bind(this);
    this.handleMonthCheckbox = this.handleMonthCheckbox.bind(this);
    this.handleCollectionCheckbox = this.handleCollectionCheckbox.bind(this);
  }

  selectAllYears(e) {
    if (e.target.checked) {
      this.props.addYears([...range(1902, 1913)]);
    } else {
      this.props.removeYears();
    }
  }

  handleYearCheckbox(e) {
    const year = parseInt(e.target.id.split("-")[2]);
    if (e.target.checked) {
      this.props.addYear(year);
    } else {
      this.props.removeYear(year);
    }
  }

  applyFilters(){
    this.props.perfromSearch( document.querySelector("#search_field").value,  
                                this.props.filters )
    this.props.toggleModal(false)
}

  handleMonthCheckbox(e) {
    const month = e.target.id.split("-")[2];
    if (e.target.checked) {
      this.props.addMonth(month);
    } else {
      this.props.removeMonth(month);
    }
  }

  
  getYearRange(collection) {
    switch (collection) {
      case 'The Muslim Herald':
        return { start: 1961, end: 1994 };
      case 'The Ahmadiyya Bulletin':
        return { start: 1969, end: 2000 };
      default:
        return { start: 1961, end: 2000 };
    }
  }

  handleCollectionCheckbox(e) {
    const collection = e.target.id.split("-")[1];
    
    if (e.target.checked) {
      this.props.addCollection(collection);
      const { start, end } = this.getYearRange(collection);
    //   this.props.addYears([...range(start, end)]);
    } else {
      this.props.removeCollection(collection);
      this.props.removeYears();
    }
  }

  render() {
    const { filters } = this.props;
    const collections = filters.collections || [];
    const years = filters.years || [];
    const months = filters.months || [];

    const yearRange = collections.length === 1
      ? this.getYearRange(collections[0])
      : { start: 1961, end: 2000 };
    return (
      <div>
        {/* Collections section */}
        <Collapsible
          defaultExpanded={true}
          header="Collections"
          body={
            <div className="filters-flex">
              <MyCheckbox
                checked={collections.includes("The Muslim Herald")}
                onChange={this.handleCollectionCheckbox}
                label="The Muslim Herald"
                id="collection-The Muslim Herald"
              />
              <MyCheckbox
                checked={collections.includes("The Ahmadiyya Bulletin")}
                onChange={this.handleCollectionCheckbox}
                label="The Ahmadiyya Bulletin"
                id="collection-The Ahmadiyya Bulletin"
              />
            </div>
          }
        />
        {/* Years section */}
        <Collapsible
          defaultExpanded={true}
          header="Years"
          preBody={
            <MyCheckbox onChange={this.selectAllYears} label="Select all" />
          }
          body={
            <div className="filters-flex">
              {[...range(yearRange.start, yearRange.end)].map((year) => (
                <div key={year} className="width-content margin-left-40p">
                  <MyCheckbox
                    checked={years.includes(year)}
                    onChange={this.handleYearCheckbox}
                    label={String(year)}
                    id={"history-year-" + year}
                  />
                </div>
              ))}
            </div>
          }
        />

        {/* Months section */}
        <Collapsible
          defaultExpanded={true}
          header="Months"
          body={
            <div className="filters-flex">
              {this.months.map((month) => (
                <div key={month} className="width-content margin-left-40p">
                  <MyCheckbox
                    checked={months.includes(month)}
                    onChange={this.handleMonthCheckbox}
                    label={month}
                    id={`history-month-${month}`}
                  />
                </div>
              ))}
            </div>
          }
        />
        <div className="apply-filters-container">
          <button
            onClick={this.applyFilters}
            className="btn apply-filters-btn margin-auto display-block"
          >
            Apply and Search
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    perfromSearch: (term, filters) => dispatch(searchAction(term, 0, filters)),
    addYear: (year) => dispatch(addYearAction(year)),
    addYears: (years) => dispatch(addYearsAction(years)),
    removeYear: (year) => dispatch(removeYearAction(year)),
    removeYears: () => dispatch(removeYearsAction()),
    toggleModal: (value) => dispatch(toggleModalAction(value)),
    addMonth: (month) => dispatch(addMonthAction(month)),
    removeMonth: (month) => dispatch(removeMonthAction(month)),
    addCollection: (collection) => dispatch(addCollectionAction(collection)),
    removeCollection: (collection) =>
      dispatch(removeCollectionAction(collection)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HIstoryFilter);
