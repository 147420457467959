const initState = {
    pending: []
}

const pdfReducer = (state=initState, action) => {
    switch(action.type){
        case 'ADD_PENDING': {
            const id = "pdf-" + (state.pending.length + 1)

            state = {
                ...state,
                pending: [...state.pending, {id, term: action.data.term, duration: action.data.duration}]
            }
            break;
        }
    }
    return state
}

export default pdfReducer;