import ThemeToggler from './layout/ThemeToggler'
import MediaQuery from 'react-responsive';
import React, { useState,useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from './layout/Switch';
import Link from '@material-ui/core/Link';
import SettingsIcon from '@material-ui/icons/Settings';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LangToggler from './layout/langToggler';
import langdata from '../constants/langdata';
import { LocalizationContext } from '../localizationContext';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


export default function SettingsComp() {
    const [open, setOpen] = useState(false)
    const { language } = useContext(LocalizationContext)

   
    return open ? (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)} >
            {langdata[language]['buttons']['Settings']}
            </DialogTitle>
            <div className="margin-top-2">
                <ul className="settings-list margin-left-5p padding-left-5p">
                    <li><ThemeToggler /></li>
                    <li><LangToggler /></li>
                    <MediaQuery minWidth={993}>
                        <li><Switch label={"PDF alerts"}/></li>
                    </MediaQuery>
                </ul>
            </div>
        </Dialog>
    ) : (
      

        <div style={{ maxwidth: '100%' } }>
            <Link 
            component="button" 
            onClick={() => setOpen(true)}
            style={{
              alignItems:'center',
              display: 'grid', gridTemplateColumns: '0.5fr 0.5fr',
              color:'lightgray'
            }}
            >
                <SettingsIcon fontSize="large" ></SettingsIcon><p className='icon-link'>{langdata[language]['buttons']['Settings']}
</p>
            </Link>
        </div>
              
    )
}
