import React, { useEffect, useState } from "react";
import { URDU_BOOKS_INDEX,ALBADAR_INDEX, ALHAKAM_INDEX, ASK_ISLAM_INDEX, DARSUL_QURAN_INDEX, backendUrl, queryDetailPath,queryBookmarkPath } from '../../backendInfo';
import BaseModal from '../layout/BaseModal';
import MySpinner from '../layout/MySpinner';
import Cookies from 'js-cookie';
import axios from 'axios';
import { itemInfo, getTextClass, showLivePDFButton, showPDFButton, showAudioBlock, showOpenLinkButton, showYoutubeLinkButton, getDetailItemInfoToggle, signedUrl } from './ResultFunctions';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import { HOLY_QURAN_INDEX } from "../../backendInfo";
import Bookmark from "../layout/Bookmark";
import { Grid } from "@material-ui/core";
import DisclaimerText from "../layout/disclaimerText";
import langdata from "../../constants/langdata";
import { queryUrl } from "../../backendInfo";


export const hQDetailItemStyles = {
    marginBottom: '18px',
    borderRadius: '8px',
    border: '1px solid #f3f3f4',
    boxShadow: '1px 1px 2px #f3f3f3',
    padding: '6px'
}


export const getHQDetailItemInfoStyles = lang => {
    if (lang === 'urdu'){
        return {
            display: 'block',
            textAlign: 'right',
            color: '#919191'
        }
    }
    else if (lang === 'en'){
        return {
            display: 'block',
            textAlign: 'left',
            color: '#919191'
        }
    }
}


const makePrevQueryParams = queryParams => {
    if(queryParams.page_no){
        return {...queryParams, page_no: queryParams.page_no - 1}
    }
    else if(queryParams.para_no){
        return {...queryParams, para_no: queryParams.para_no - 1}
    }
    else if(queryParams.verse){
        return {...queryParams, verse: queryParams.verse - 1}
    }
    return queryParams
}


const makeNextQueryParams = queryParams => {
    if(queryParams.page_no){
        return {...queryParams, page_no: queryParams.page_no + 1}
    }
    else if(queryParams.para_no){
        return {...queryParams, para_no: queryParams.para_no + 1}
    }
    else if(queryParams.verse){
        return {...queryParams, verse: queryParams.verse + 1}
    }
    return queryParams
}


const OpenBookPageButton = ({ url, queryParams }) => {
    const [buttonUrl, setButtonUrl] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        const fetchUrl = async () => {
            const access = Cookies.get('token');

            if (signedUrl(queryParams) && String(access) !== "null") {
                try {
                    const res = await axios.post(
                        `${backendUrl}${queryUrl}`,
                        { queryParams },
                        { headers: { Authorization: `Bearer ${access}` } }
                    );

                    if (res.data && res.data.signed_url) {
                        setButtonUrl(res.data.signed_url);
                    }
                } catch (error) {
                    console.error('Error fetching URL:', error);
                    // Optional: Provide feedback to the user
                }
            } else {
                setButtonUrl(url); // Fallback to the initial URL if token is null
            }
        };

        setText(langdata[localStorage.getItem('lang-pref')]['buttons']['Open page in Book']);
        fetchUrl();
    }, [queryParams]); // Remove 'url' if it's not required to trigger fetching

    return (
        <a
            id="openInBookBtn"
            className="btn btn-small"
            href={buttonUrl || url} // Use buttonUrl if set, otherwise fallback to `url`
            target="_blank"
            rel="noopener noreferrer"
        >
            {text}
        </a>
    );
};



const OpenOriginalLinkButton = ({ url }) => {
    return <a 
            id="OpenOriginalLinkButton" 
            className="btn btn-small" 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer"
            >{langdata[localStorage.getItem('lang-pref')]['buttons']['Open Original Link']}</a>
}

const OpenYoutubeLinkButton = ({ url }) => {
    return <a 
            id="OpenOriginalLinkButton" 
            className="btn btn-small" 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer"
            >{langdata[localStorage.getItem('lang-pref')]['buttons']['Open Youtube Link']}</a>
}

const PlayAudio = ({ audioUri }) => {
    return <audio id="audioBlock" controls src={audioUri}  />
}


// const OpenLivePageButton = ({ url }) => {
//     return <a 
//             id="openInPDFBtn" 
//             className="btn btn-small" 
//             href={url} 
//             target="_blank" 
//             rel="noopener noreferrer"
//             >Open page on Al-Islam</a>
// }


const EmptyPageMessage = () => {
    return <>
        <p style={{
            textAlign: 'center', 
            color:'#979797',
            paddingTop: '25%'
            }}>We're sorry, but we couldn't find that page.</p>
    </>
}


const Title = ({ queryParams, index  }) => {
    return <div>
        <span >{ itemInfo(queryParams, index) }</span>
        { showPDFButton(queryParams, index) && <OpenBookPageButton url={queryParams.url} queryParams={queryParams} /> }
        {/* { showLivePDFButton(queryParams) && <OpenLivePageButton url={queryParams.url_live}/>} */}
        { showOpenLinkButton(queryParams, index) && <OpenOriginalLinkButton url={queryParams.ask_islam_link || queryParams.url}/>}
        { showYoutubeLinkButton(queryParams, index) && <OpenYoutubeLinkButton url={queryParams.youtube_link}/>}
        {/* { showAudioBlock(index) && <PlayAudio audioUri={queryParams.mp3_link}/>} */}

    </div>
}


const ScrollButton = props => {
    const { previous, queryParams, fetchDetail, handleResponse, setCurrParams} = props
    const icon = previous ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
    

    // const label = previous ? "Previous Page" : "Next Page"
    const displayLabel = previous ? langdata[localStorage.getItem('lang-pref')]['buttons']['Previous'] :langdata[localStorage.getItem('lang-pref')]['buttons']['Next'];
    let label;
    if (queryParams.tab === 'friday_sermon') {
        label = `${displayLabel} ${langdata[localStorage.getItem('lang-pref')]['buttons']['Paragraph']}`;
    } else {
        label = `${displayLabel} ${langdata[localStorage.getItem('lang-pref')]['buttons']['Page']}`;
    }

    return <Button
            size="small"
            variant="contained"
            style={{ 
                display:"flex", 
                margin:"auto",
                marginTop: previous ? "auto" : "20px" 
            }} 
            onClick={() => {
                setCurrParams(queryParams)
                fetchDetail(queryParams, handleResponse)
            }}
            startIcon={icon}
        >
        {label}
    </Button>
}


const ContentHolder = props => {
    // props.content : list of elements
    const { 
        content, 
        showPrev, 
        showNext,
        prevButton,
        nextButton
    } = props
    
    return content.length > 0 ? (
        <>
            { showPrev && prevButton }
            <div className="detail-content">
                { content }
            </div>
            { showNext && nextButton }
        </>
    ) : <MySpinner display={true}/>

}


export const HitRenderer = ({ hit }) => {
    // Data-specific complexity in here

    //Render Hit here
    if(hit._index === HOLY_QURAN_INDEX){
            let elements = []
            const fieldNumbers = [1,2,3,4,5,6,7,8,9]
            
            elements = elements.concat(
                <p
                key={hit._id} 
                className="hQArabicText"
                style={{ marginTop: '50px' }}
                dangerouslySetInnerHTML={{__html: hit._source.arabic}}
                ></p>
            )
            
            elements = elements.concat(fieldNumbers.map(num => {
                const textField = `col${num}`
                const infoField = `col${num}_info`
                const langField = `col${num}_lang`
                const text = hit.highlight && 
                            Object.keys(hit.highlight).includes(textField)
                            ? hit.highlight[textField][0] : hit._source[textField]
                
                if (hit._source[textField] !== null){
                    return <div key={hit._id + textField} style={hQDetailItemStyles}>
                            <p 
                            dangerouslySetInnerHTML={{__html: text}} 
                            className={getTextClass(hit._source[langField])}
                            >
                            </p>
                            <span
                            className={hit._source[langField] === 'urdu' ? "urdu-text" : "text-align-left"}
                            style={getHQDetailItemInfoStyles(hit._source[langField])}
                            >
                            {hit._source[infoField]}
                            </span>
                        </div>
                }
                
            }))

            return elements
        }
    // else if(  ){
    //     const hitText = hit.highlight ? hit.highlight.text[0] : hit._source.text
    //     return <>
    //             <p 
    //             className={getTextClass(hit._source.lang)}
    //             dangerouslySetInnerHTML={{__html: hitText}}     
    //             ></p>
    //         </>
    //     }
    else{
        const hitText = hit.highlight ? hit.highlight.text[0].replace(/\n/g, '<br>') : hit._source.text.replace(/\n/g, '<br>')
        return <>
                <p 
                className={getTextClass(hit._source.lang)}
                dangerouslySetInnerHTML={{__html: hitText}}     
                ></p>
                {getDetailItemInfoToggle(hit._index) && 
                    <p 
                    className="width-content margin-auto"
                    >{itemInfo(hit._source, hit._index)}</p>
                }
            </>
    }
}


const NewResultDetails = ({ isOpen, onClose, queryParams }) => {
    const [content, setContent] = useState([])
    const [showNextButton, toggleNextButton] = useState(true)
    const [showPrevButton, togglePrevButton] = useState(true)
    const [indexName, setIndexName] = useState(null)
    const [currQueryParams, setCurrQueryParams] = useState(queryParams)
    const [saved,setSaved]=useState(false)
   
    
    
   
    const saveDetail=(queryParams) => {
        const access = Cookies.get('token');
        if(String(access) !== "null"){          
            axios.post(
                `${backendUrl}${queryBookmarkPath}`,
                { 
                    params: queryParams, 
                },
                  {  headers: { Authorization: `Bearer ${access}` } }
            )
            .then(res => {
                setSaved(true)
            })
        }
        else{}
       
    }

    const fetchDetail = (queryParams, handleResponse) => {
        const access = Cookies.get('token');
        if(String(access) !== "null"){          
            axios.get(
                `${backendUrl}${queryDetailPath}`,
                { 
                    params: queryParams, 
                    headers: { Authorization: `Bearer ${access}` } 
                }
            )
            .then(res => {
                handleResponse(res)
            })
        }
        else{}
    }

    const handleResponse = res => {  
        let hits = res.data.hits.hits  
        let elements = Object.values(hits).map(
            hit => { 
                !indexName && setIndexName(hit._index)
                return <HitRenderer hit={hit} key={ hit._id } />  
            }
        )
        if (elements.length > 0)
            setContent(elements)
        else
            setContent([<EmptyPageMessage key={'5xkw25m^l2'}/>])
    }

    const updatePageConfigs = () => {
        if (indexName && indexName === ASK_ISLAM_INDEX){
            toggleNextButton(false)
            togglePrevButton(false)
        }
        else if (indexName && indexName === DARSUL_QURAN_INDEX){
            toggleNextButton(false)
            togglePrevButton(false)
        }
        // else if (indexName && indexName === ALBADAR_INDEX){
        //     toggleNextButton(false)
        //     togglePrevButton(false)
        // }
        // else if (indexName && indexName === ALHAKAM)_INDEX){
        //     toggleNextButton(false)
        //     togglePrevButton(false)
        // }
        else{

        }
    }

    useEffect(() => {
        fetchDetail(currQueryParams, handleResponse)
        updatePageConfigs()
    }, [])

    useEffect(() => {
        updatePageConfigs()
    }, [indexName])

    return isOpen ? (
        <>
            <BaseModal
                content={
                    <ContentHolder 
                        content={content}
                        showPrev={showPrevButton} 
                        showNext={showNextButton}
                        prevButton={
                            <ScrollButton 
                                previous={true}
                                queryParams={makePrevQueryParams(currQueryParams)}
                                handleResponse={handleResponse}
                                setCurrParams={setCurrQueryParams}
                                fetchDetail={fetchDetail}
                            />}
                        nextButton={
                            <ScrollButton 
                                queryParams={makeNextQueryParams(currQueryParams)}
                                handleResponse={handleResponse}
                                setCurrParams={setCurrQueryParams}
                                fetchDetail={fetchDetail}
                            />
                        }
                    />
                }
                title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
                 <Grid item xs={indexName === URDU_BOOKS_INDEX ? 12 : 11}> {/* Adjust the size of the Title item */}
                    <Title index={indexName} queryParams={currQueryParams}/>
                </Grid>
                <Grid xs={indexName === URDU_BOOKS_INDEX ? 0 : 1} style={{textAlign:'center'}}> {/* Adjust the size of the Bookmark item */}
                    
                {indexName !== URDU_BOOKS_INDEX && <Bookmark isSaved={saved} onClick={() => saveDetail(currQueryParams)} />}
                </Grid>
            </Grid>}
                isOpen={isOpen} 
                handleClose={onClose}
                titlePosition="bottom"
                disclaimer={<DisclaimerText index={indexName}/>}
            />
        </>
    ) : null

}


export default NewResultDetails;