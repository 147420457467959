
import React, { useState, useContext,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom'; // Assuming you're using React Router
import Logo from './layout/Logo';
import langdata from '../constants/langdata';
import { LocalizationContext } from '../localizationContext';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    position: 'relative', // Add position relative to allow absolute positioning of logo
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  content: {
    lineHeight: '1.6',
  },
  button: {
    marginTop: theme.spacing(4),
  },
  logoBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0)', // Adjust opacity as needed
    zIndex: -1,
  },
}));

function AboutUs() {
  const classes = useStyles();
  const history = useHistory();
  const { language } = useContext(LocalizationContext);

  const handleGoHome = () => {
    history.push('/'); // Replace '/' with your home route
  };

  return (
    <Container className={classes.root}>
      <div className={classes.logoBackground}></div> {/* Add the logo background */}
      <Logo /> {/* Render your logo component */}
      <Typography variant="h4" component="h1" className={classes.title}>
      {langdata[language]['buttons']['About Us']}
      </Typography>
      <Typography variant="body1" className={classes.content}>
        {/* Your lorem ipsum text */}
      </Typography>
      <Button variant="contained" color="#778899" className={classes.button} onClick={handleGoHome}>
        {langdata[language]['buttons']['Back']}
      </Button>
    </Container>
  );
}

export default AboutUs;
