import React, { useState, useEffect } from 'react';
import BookmarkIcon from '@material-ui/icons/BookmarkBorder';
import SavedBookmarkIcon from '@material-ui/icons/Bookmarks';

const Bookmark = ({ isSaved, onClick }) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    let timer;
    if (isSaved) {
      setShowText(true);
      timer = setTimeout(() => {
        setShowText(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isSaved]);

  return (
    <div onClick={onClick} style={{ position: 'relative', display: 'inline-block' }}>
      {isSaved ? (
        <>
          <SavedBookmarkIcon fontSize="large" />
          {showText && <div style={{ position: 'absolute', top: '-35px', left: '50%', transform: 'translateX(-50%)', background: '#26a69a', color: '#fff', padding: '4px', borderRadius: '4px',fontSize:"10px" }}>Saved</div>}
        </>
      ) : (
        <div style={{ display: 'inline-block', alignContent: 'left' }}>
          <BookmarkIcon fontSize="large" />
          <div style={{ position: 'absolute', top: '-20px', left: '50%', transform: 'translateX(-50%)', visibility: 'hidden' }}>Saved</div>
        </div>
      )}
    </div>
  );
};

export default Bookmark;
