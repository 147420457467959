export const showDetailsAction = details => {
    return {type: 'SHOW_RESULT_DETAILS', details}
}

export const toggleDetailsModalAction = value => {
    return {type: 'TOGGLE_DETAILS_MODAL', value}
}

export const clearDetailsAction = () => {
    return {type: 'CLEAR_DETAILS' }
}