import React, { Component } from "react";
import { connect } from "react-redux";
import { changeSortOrderAction } from "../../store/actions/filtersActions";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import langdata from "../../constants/langdata";

class SortSelection extends Component {
  state = {
    sortTextToTab: this.getSortTextToTab(),
  };

  constructor(props) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  getSortTextToTab() {
    const langPref = localStorage.getItem("lang-pref") || "eng";
    if (langdata.hasOwnProperty(langPref)) {
      return langdata[langPref]["sortTextToTab"];
    } else {
      return langdata["eng"]["sortTextToTab"];
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.updateBtnTextToTab(this.props.lang);
    }
  }

  updateBtnTextToTab(lang) {
    const langPref = localStorage.getItem("lang-pref") || "eng";
    if (langdata.hasOwnProperty(langPref)) {
      const btnTextToTab = langdata[langPref]["sortTextToTab"];
      this.setState({ sortTextToTab: btnTextToTab });
    } else {
      const btnTextToTab = langdata["eng"]["sortTextToTab"];
      this.setState({ sortTextToTab: btnTextToTab });
    }
  }

  onButtonClick(e) {
    const btnText = e.target.innerText.trim();
    let sortOrder;

    if (
      this.state.sortTextToTab &&
      this.state.sortTextToTab.hasOwnProperty(btnText)
    ) {
      sortOrder = this.state.sortTextToTab[btnText];
    }

    if (sortOrder) {
      let btns = Array.from(document.getElementsByClassName("sort-select-btns"));
      btns.forEach((b) => {
        if (b.id === sortOrder) {
          b.classList.toggle("pressed-btn");
        } else {
          b.classList.remove("pressed-btn");
        }
      });

      this.props.changeSortOrder(sortOrder);
    }
  }

  render() {
    return (
      <ButtonGroup
        color="default"
        disableRipple={true}
        disableFocusRipple={true}
        disableElevation
        onClick={this.onButtonClick}
        variant="contained"
        aria-label="contained primary button group"
      >
        {Object.keys(this.state.sortTextToTab).map((key, index) => {
          const sortOrder = this.state.sortTextToTab[key];
          return (
            <Button
              key={index}
              className={`sort-select-btns ${index === 0 ? "pressed-btn" : ""}`}
              id={sortOrder}
            >
              {key}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
    lang: state.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      changeSortOrder: (value) => dispatch(changeSortOrderAction(value)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(SortSelection);