import React, { Component,useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/Info';
import { Link, List, ListItem, Typography } from '@material-ui/core';
import FeedbackForm from '../feedback';
import History from '../History';
import SavedBookmark from '../SavedBookmark';
import SettingsComp from '../SettingsComp';
import Button from '@material-ui/core/Button';
import langdata from '../../constants/langdata';
import { LocalizationContext } from '../../localizationContext';

const drawerWidth = '500px';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

class Navbar extends Component {
  static contextType = LocalizationContext;
  constructor(props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.handleAboutClickNav = this.handleAboutClickNav.bind(this);
  }

  state = {
    openDrawer: false
  };

  handleMenuClick() {
    this.setState({ openDrawer: true });
  }

  closeDrawer() {
    this.setState({ openDrawer: false });
  }

  handleAboutClickNav() {
    this.props.history.push('/about');
  }

  render() {
    const { language } = this.context;

    return (
      <Box sx={{ display: 'flex', height: '100%' }}>
        <CssBaseline />
        <IconButton
          onClick={this.handleMenuClick}
          edge="end"
          color='lightgray'
          aria-label="menu"
          style={{ position: 'fixed', right: 20, top: 5 , color:'lightgray'}}
        >
          <MenuIcon />
        </IconButton>
        <DrawerHeader />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              maxHeight: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          variant="persistent"
          anchor="right"
          open={this.state.openDrawer}
        >
          <DrawerHeader>
            <IconButton onClick={this.closeDrawer}>
              <ChevronRightIcon style={{color:'lightgray'}}/>
            </IconButton>
          </DrawerHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'fixed', bottom: 0, width: '15.5%',overflow:'hidden' }}>
            <List style={{ alignContent: 'center' }}>
              <ListItem>
                <FeedbackForm
                  params={{
                    searchTerm: this.props.searchTerm || '',
                    tab: (this.props.filters && this.props.filters.tab) || 'defaultTab',
                    offset: this.props.offset || 0,
                    filters: this.props.filters || {}
                  }}
                />
              </ListItem>
              <ListItem>
                <SavedBookmark />
              </ListItem>
              <ListItem>
                <div style={{ maxWidth: '100%' }}>
                  <Link 
                    to="/about"
                    component="button" 
                    onClick={this.handleAboutClickNav}
                    style={{
                        alignItems:'center',
                        display: 'grid', gridTemplateColumns: '0.5fr 0.5fr',
                        color: 'lightgray'
                    }}
                    >
                    <InfoIcon fontSize='large' /><p className='icon-link'> {langdata[language]['buttons']['About Us']}</p>
                   
                  </Link>
                </div>
              </ListItem>
              <ListItem>
                <SettingsComp />
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Box>
    );
  }
}

export default withRouter(Navbar);
