const initState = {
    results: [],
    offset: 0,
    totalResults: 0,
}

const resultsReducer = (state=initState, action) => {
    switch(action.type){
        case 'SHOW_RESULTS': {
            state = {
                ...state,
                results: [...state.results, ...action.results.hits.hits],
                totalResults: action.results.hits.total.value,
            }
        
            state = { ...state, offset: state.results.length }
            break;
        }

        case 'CLEAR_RESULTS': {
            state = {
                ...state,
                results: []
            }
            break;
        }
        
    }
    return state
}

export default resultsReducer;


