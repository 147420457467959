import axios from 'axios';
import Cookies from 'js-cookie';
import {backendUrl, filtersMetadataPath} from '../../backendInfo';


export const fetchFilters = async (tab) => {
    const res = await axios.get(backendUrl+filtersMetadataPath+'fetch-filters/', 
                                {   
                                    params: {tab},
                                    headers: {
                                        Authorization: `Bearer ${Cookies.get('token')}`
                                    }
                                })
    
    const collsByAuthors = res.data["colls_by_author"]
    const authors = res.data["authors"]
    const otherColls = res.data["other_colls"]
    
    return [authors, collsByAuthors, otherColls]
}
