import { searchAction } from '../store/actions/searchActions';
import { verifyTokenAction } from '../store/actions/authActions';
import ResultDetails from './results/ResultDetails';
import SearchSection from './search/SearchSection';
import ResultList from './results/ResultList';
import { setTheme } from '../GlobalFunctions';
import { Redirect } from 'react-router-dom';
import MySpinner from './layout/MySpinner';
import MediaQuery from 'react-responsive';
import SettingsComp from './SettingsComp';
import React, { Component } from 'react';
import Message from './layout/Message';
import { connect } from 'react-redux';
import Navbar from './layout/Navbar';
import PDFAlerts from './PDFAlerts';
import Logo from './layout/Logo';
import History from './History';
import InfoIcon from '@material-ui/icons/Info';
import { Button, Link } from '@material-ui/core';
import SavedBookmark from './SavedBookmark';
import FeedbackForm from './feedback';

class Home extends Component{
    
    constructor(props){
        super(props);
        this.loadOnScroll = this.loadOnScroll.bind(this);
        this.handleaboutclick = this.handleaboutclick.bind(this)
    }

    componentDidMount(){
        this.props.verifyToken();
        setTheme();
        this.props.history.push('/');
    }

    loadOnScroll(lastChild){
        if(lastChild && window.innerHeight + document.documentElement.scrollTop >= lastChild.offsetTop){
            // console.log(this.props.awaitSearch )
            // console.log(this.props.searchEnded )  //go to the very bottom
              //go to the very bottom
            if (!this.props.awaitSearch && !this.props.searchEnded){
                this.props.performSearch(this.props.searchTerm, this.props.offset, this.props.filters)                    
            }
        }
    }
     handleaboutclick() {
        this.props.history.push('/about');
    }
  
    render(){

        const {
            isAuthenticated, 
            awaitAuth,
            searchTerm, 
            awaitSearch, 
            results, 
            resultDetails,
            totalResults,
            filters,
        } = this.props;

        if (awaitAuth){
            return (
                <div className="height-100 padding-top-40">
                    <MySpinner display={true}/>
                </div>
            )
        }
        else if(!isAuthenticated){
            return <Redirect to="/signin" />
        }
        else if(isAuthenticated){   
            if(results.length){    
                window.onscroll = () => this.loadOnScroll(document.querySelector('.result-list') ? document.querySelector('.result-list').lastChild: null);
            }
           
                
            return (
                <>
                    <div className="row" style={{height:'100%'}}>
                        <div className="padding-zero margin-zero col s12 l2">
                            <MediaQuery maxWidth={992} >
                                <Navbar />
                            </MediaQuery>
                                <Logo size="small"/>
                                
                           
                        </div>
                        <div className="col s12 l8 main-column">
                            <SearchSection/>
                            <ResultDetails details={resultDetails.hits} isOpen={resultDetails.openDetailsModal}/>    
                            <ResultList tab={filters.tab} totalResults={totalResults} results={results} searchTerm={searchTerm}/>
                            <Message/>
                            <MySpinner display={awaitSearch}/>
                        </div>
                        <div className="col l2 right-column">
                            <MediaQuery minWidth={993}>
                                <Navbar/>
                            </MediaQuery>
                        </div>
                    </div>
                </>
            )   
        }
    }
}


const mapStateToProps = (state) => {
    return {
        // auth
        isAuthenticated: state.auth.isAuthenticated,
        awaitAuth: state.auth.awaitAuth,
        // search
        searchTerm: state.search.searchTerm,
        searchEnded: state.search.searchEnded,
        awaitSearch: state.search.awaitSearch,
        // results
        results: state.results.results,
        offset: state.results.offset,
        totalResults: state.results.totalResults,
        // resultDetails
        resultDetails: state.resultDetails,
        // filters
        filters: state.filters
        //lang
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        verifyToken: (token) => dispatch(verifyTokenAction(token)),
        performSearch: (term, offset, filters) => dispatch(searchAction(term, offset, filters)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);