import React, { useState, useContext,useEffect } from 'react';
import axios from 'axios'; 
import BookmarkIcon from '@material-ui/icons/BookmarkRounded';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import Cookies from 'js-cookie'; 
import { backendUrl, getqueryBookmarkPath } from '../backendInfo';
import ResultItem from './results/ResultItem';
import langdata from '../constants/langdata';
import { LocalizationContext } from '../localizationContext';

const SavedBookmark = () => {
    const [open, setOpen] = useState(false);
    const [savedDetail, setSavedDetail] = useState(null);
    const { language } = useContext(LocalizationContext);

    useEffect(() => {
        getSavedDetail(); 
    }, []);

  

    const getSavedDetail = async () => {
        const access = Cookies.get('token');
        if (String(access) !== "null") {
            try {
                const res = await axios.get(
                    `${backendUrl}${getqueryBookmarkPath}`,
                    { headers: { Authorization: `Bearer ${access}` } }
                );
                setSavedDetail(res.data);
            } catch (error) {
                console.error('Error fetching saved detail:', error);
            }
        } else {
            setSavedDetail([]);
        }
    }

    const handleClick = () => {
        setOpen(true); 
        getSavedDetail(); 
    };

    return (
        <>
            <Dialog open={open} fullWidth={true} maxWidth={'md'} scroll='paper'>
                <MuiDialogTitle disableTypography>
                    <Typography variant="h6">{langdata[language]['buttons']['Bookmarks']}</Typography>
                    <IconButton aria-label="close" onClick={() => setOpen(false)} style={{ position: 'absolute', top: '8px', right: '8px' }}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <div className="margin-top-2" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    {savedDetail && savedDetail.map((detail, index) => (
                        <ResultItem 
                            resultItem={detail.result.hits.hits[0]} 
                            tab={detail.tab} 
                            qtype={detail.qtype}
                            key={index}
                        />
                    ))}
                </div>
            </Dialog>
            <div style={{ maxWidth: '100%' }}>
                <Link
                    component="button"
                    onClick={handleClick}
                    style={{
                        alignItems:'center',
                        display: 'grid', gridTemplateColumns: '0.5fr 0.5fr',
                        color: 'lightgray'
                    }}
                >
                    <BookmarkIcon fontSize="large" />
                    <p className='icon-link'>{langdata[language]['buttons']['Bookmarks']}</p>
                </Link>
            </div>
        </>
    );
}

export default SavedBookmark;
