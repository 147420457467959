import React from 'react';
import { connect } from 'react-redux';


const Message = ({message}) => {
    return (
        <div className="message">
            <p className="width-content margin-auto padding-top-10">{ message }</p>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        message: state.message.message
    }
}

export default connect(mapStateToProps)(Message)