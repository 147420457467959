import React from 'react';
import Login from './components/Login'
import Home from './components/Home'
import AboutUs from './components/AboutUs';
import { LocalizationProvider } from './localizationContext';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <LocalizationProvider>
            <Switch>
              <Route path='/' exact component={Home} />
              <Route path='/about' component={AboutUs} />
              <Route path='/signin' component={Login} />

            </Switch>
          </LocalizationProvider>
      </div>
    </BrowserRouter>
  );
}


export default App;
