import React from 'react';


const Switch = (props) => {
    return <div className="switch">
        <span>{props.label}</span>
        <label className="right">
            <input id="theme-switch"
                checked={props.checked} 
                onChange={props.onChange} 
                type="checkbox" />
            <span className="lever"></span>
        </label>
    </div>
}

export default Switch;