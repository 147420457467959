import {backendUrl, requestPDFPath, takeawayPDFPath} from '../../backendInfo'
import {addPDFAction} from '../../store/actions/pdfActions';
import MySpinner from '../layout/MySpinner'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import Cookies from 'js-cookie'
import axios from 'axios'


class Export extends Component{
    state = {
        requested: false,
        requesting: false,
        fileID: null,
        data: null
    }

    openPDF = (data) => {
        const url = window.URL.createObjectURL(new Blob([data], {type : 'application/pdf'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.props.term} Search Results - Minnatul Aleem App.pdf`); // Added .pdf extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
    }


    showAlert(term, noOfResults){
        if(noOfResults > 50){
            this.props.addPDFAlert(term, noOfResults)
        }
    }
    

    createParams(term, filters, size){
        delete filters['isOpen']
        filters.term = term
        filters.size = size
        return filters
    }


    requestPDF = (e) => {
        this.setState({ requesting: true })
        const { term, filters, size } = this.props
        const access = Cookies.get('token')
        const params = this.createParams(term, filters, size)
        
        axios.get(`${backendUrl}${requestPDFPath}`, { params, headers: { Authorization: `Bearer ${access}` } })
        .then(res => {
            // console.log(res)
            this.setState({fileID: res.data.split(',')[0], requesting: false, requested: true})
            this.showAlert(term, parseInt(res.data.split(',')[1]))
            
            const checkIn = setInterval(() => {
                const access = Cookies.get('token')
                const params = {fileID: this.state.fileID}
                
                axios.get(`${backendUrl}${takeawayPDFPath}`, { params, responseType: 'blob', headers: { Authorization: `Bearer ${access}` } })
                .then(res => {
                    // Ensure the response is a blob and has the correct type
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    if (blob instanceof Blob) {
                        clearInterval(checkIn);
                        this.openPDF(blob);
                    } else {
                        console.error("Response is not a Blob:", res.data);
                    }
                })
                .catch(err => console.log(err))
            }, 5000)
        })
        .catch(err => console.log(err))
    }
    render(){
        if(this.state.requesting){
            return (
                <div className="margin-auto width-content">
                    <MySpinner display={true}/>
                </div>
                )
        }
        else if (this.state.requested){
            return <p className="margin-auto width-content">Request recieved. Your PDF will download automatically when its complete.</p>
        }
        else{
            return (
                <div className="width-content margin-auto">
                    <a className="btn-small black-text waves-effect" id="pdf-export-btn" onClick={this.requestPDF}>Export as PDF</a>
                </div>
            )
        }
    }
}


const mapDispatchToProps = dipatch => {
    return {
        addPDFAlert: (term, noOfResults) => dipatch(addPDFAction(term, noOfResults))
    }
}

const mapStateToProps = (state) => {
    return {
        term: state.search.searchTerm,
        filters: state.filters,
        size: state.results.offset
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Export)