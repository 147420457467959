const initState = {
    searchTerm: '',
    awaitSearch: false,
    searchEnded: false,
}

const searchReducer = (state = initState, action) => {
    
    switch(action.type){
        case 'CHANGE_AWAIT_SEARCH': {
            state = {
                ...state,
                awaitSearch: action.value
            }
            break;
        }
        case 'SEARCH_ENDED': {
            state = {
                ...state,
                searchEnded: action.value
            }
            break;
        }
        case 'SET_SEARCH_TERM': {
            state ={
                ...state,
                searchTerm: action.term
            }
            break;
        }
    }
    
    return state
}

export default searchReducer;