import React, { Component } from "react";
import MyCheckbox from "../layout/Checkbox";
import { connect } from "react-redux";
import {
  addVolAction,
  removeVolAction,
} from "../../store/actions/filtersActions";
import SubCollectionFilters from "./SubCollectionFilters";

class VolumesSection extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const cid = e.target.id.split('-')[1]
    const vno = e.target.id.split('-')[2]
    // const useNewVolumes = volumes_new.includes(Number(cid));
    const volumeId = vno ;
    
    if (e.target.checked) {
        this.props.addVolume(volumeId)
    } else {
        this.props.removeVolume(volumeId)
    }
}


  render() {
    const { display, noOfVols, collID, classes, volumes } = this.props;
    const range = (start, end) =>
      Array.from({ length: end - start + 1 }, (v, k) => k + start);
    // const useNewVolumes = volumes_new.includes(Number(collID));

    if (display) {
      return (
        <div className={"filters-flex volume-section " + classes}>
          {range(1, noOfVols).map((vno) => {
            const volumeId = String(vno);
            return (
              <div key={vno + "asf"}>
                <MyCheckbox
                  key={vno}
                  id={"vol-" + collID + "-" + vno}
                  label={"Volume " + vno}
                  onChange={this.handleChange}
                  checked={volumes.includes(volumeId)}
                />
                <SubCollectionFilters
                  display={volumes.includes(String(collID) + "-" + String(vno))}
                  collID={collID}
                  vno={vno}
                />
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addVolume: (vno) => dispatch(addVolAction(vno)),
    removeVolume: (vno) => dispatch(removeVolAction(vno)),
  };
};

const mapStateToProps = (state) => {
  return {
    volumes: state.filters.volumes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VolumesSection);
