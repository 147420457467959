import React, {Component} from 'react';


class MyCheckbox extends Component{
    
    
    render(){
        return (
            <label>
                <input checked={this.props.checked} id={this.props.id} type="checkbox" className="filled-in" onChange={this.props.onChange}/>
                <span>{this.props.label}</span>
            </label>
        )
    }
}

export default MyCheckbox;