import React, {Component} from 'react'
import {connect} from 'react-redux'
import TransitionAlert from './layout/Alert';
import Timer from './layout/Timer'

class PDFAlerts extends Component{
    render() {
        const {pdfs} = this.props 
        
        if(pdfs.pending.length){
            return <div className="pdf-alerts">
                    {pdfs.pending.map(pending => {
                        return <TransitionAlert 
                                title={<p>Results for {pending.term}</p>} 
                                content={<div><span>Downloading in approx. </span><Timer duration={pending.duration} id={pending.id}/></div>} 
                                key={pending.id}
                                className="margin-top-20p"
                                />
                    })}
            </div>
        }
        else return null
    }
}

const mapStateToProps = state => {
    return {
        pdfs: state.pdfs
    }
}

export default connect(mapStateToProps)(PDFAlerts);