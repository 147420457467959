import { toggleModalAction } from '../../store/actions/filtersActions';
import BaseModal from '../layout/BaseModal';
import BooksFilters from './BooksFilters';
import RORFilters from './RORFilters';
import AlfazalFilters from './AlfazalFilters';
import ArabicFilters from './ArabicFilters';
import EngBooks from './EngBooks';
import React, {Component} from 'react'
import {connect} from 'react-redux';
import FridaySermonFilters from './FridaySermonFilters';
import { LocalizationContext } from '../../localizationContext';
import langdata from '../../constants/langdata';
import MuslimSunriseFilters from './MuslimSunriseFilters';
import AlhakamFilters from './AlhakamFilters';
import AlbadarFilters from './AlbadarFilters';
import HistoryFilter from './HistoryFilter';


// const tabTitle = {
//     'books':    'Jamati Books',
//     'ror':      'Review of Religions',
//     'alfazal':  'Al-Fazal',
//     'engBooks': 'English Jamaat Books',
//     'arabicBooks': 'Arabic Books',
//     'holyQuran': 'The Holy Quran',
//     'fridaySermon': 'Friday Sermon'
// }

const modalContents = {
    'books':    <BooksFilters />,
    'ror':      <RORFilters />,
    'alfazal':  <AlfazalFilters />,
    'engBooks': <EngBooks />,
    'arabicBooks': <ArabicFilters />,
    'fridaySermon':<FridaySermonFilters/>,
    'muslimSunrise':<MuslimSunriseFilters/>,
    'alhakam':<AlhakamFilters/>,
    'albadar':<AlbadarFilters/>,
    'history':<HistoryFilter/>
}

class FiltersModal extends Component{
    static contextType = LocalizationContext;
    render(){
        const {language}=this.context;
        const tabTitle=langdata[language]['filtersTitle'];
        const {toggleModal, isOpen, tab} = this.props

        return isOpen ? (
                <BaseModal 
                handleClose={() => toggleModal(false)} 
                isOpen={isOpen} 
                content={modalContents[tab]} 
                title={tabTitle[tab]} 
                titlePosition="top"/>
                ) : null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleModal: value => dispatch(toggleModalAction(value)),
    }
}

const mapStateToProps = (state) => {
    return { 
        isOpen: state.filters.isOpen,
        tab: state.filters.tab
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersModal);