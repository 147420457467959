const initState = {
    message: ''
}

const messageReducer = (state=initState, action) => {
    if(action.type === 'SHOW_MESSAGE'){
        state = {...state, message: action.message}
    }
    if(action.type === 'CLEAR_MESSAGE'){
        state = {...state, message: ''}
    }
    
    return state
}

export default messageReducer;