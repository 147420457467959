import { replaceAllAction } from '../store/actions/filtersActions';
import { searchAction } from '../store/actions/searchActions';
import { historyPath, backendUrl } from '../backendInfo';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import axios from 'axios';


class History extends Component{
    constructor(props){
        super(props)
        this.refreshHistory = this.refreshHistory.bind(this)
        this.itemClick = this.itemClick.bind(this)
    }
    
    state = {
        searches: []
    }

    componentDidMount(){
        if(document.getElementById("refresh-history"))
            document.getElementById("refresh-history").click()
    }

    refreshHistory(e){
        const access = Cookies.get('token')
        if(String(access) !== "null"){
            axios.get(`${backendUrl}${historyPath}`, { headers: { Authorization: `Bearer ${access}` } })
            .then(res => {
                this.setState({ searches: [...res.data] })
            })
        }
    }

    itemClick(historyItem){
        let filters = JSON.parse(historyItem.params)
        filters.authors = filters.authors ? filters.authors.split(',').map(a => parseInt(a)) : []
        filters.collections = filters.collections ? filters.collections.split(',').map(c => parseInt(c)) : []
        filters.volumes = filters.volumes ? filters.volumes.split(',').map(v => parseInt(v)) : []
        filters.years = filters.years ? filters.years.split(',').map(y => parseInt(y)) : []
                
        document.getElementById("search_field").value = historyItem.term
        document.getElementById("search_field").focus()
        this.props.replaceAllFilters(filters)
        this.props.performSearch(historyItem.term, filters)
        
    }

    render(){
        return this.state.searches.length ? (
            <div>
                <div className="history-section text-align-center">
                    <p>Recent Searches</p>
                    <ul className="search-history">
                        {this.state.searches.map(search => {
                            return <li className="margin-top-20p" key={search.id}>
                                    <div onClick={e => this.itemClick(search)} className="urdu-font text-align-center history-item width-content margin-auto">
                                        <span>{search.term}</span> <br/>
                                        <span>{Object.keys(JSON.parse(search.params)).filter(k => JSON.parse(search.params)[k] !== null).length} filters applied</span>
                                    </div>    
                                </li>
                        })}
                    </ul>
                    <input onClick={this.refreshHistory} id="refresh-history" type="hidden"></input>
                </div>
                <div className="width-content margin-auto">
                    <Tooltip title="Clear History" placement="top">
                        <IconButton aria-label="clear history">
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        ) : <div className="history-section text-align-center">
            <p>
                Recent Searches <br/><br/>
                <span className="grey-text">
                    Nothing here
                </span>
            </p>
            <input onClick={this.refreshHistory} id="refresh-history" type="hidden"></input>
        </div>
    }
}



const mapDispatchToProps = dispatch => {
    return {
        replaceAllFilters: newFilters => dispatch(replaceAllAction(newFilters)),
        performSearch: (term, filters) => dispatch(searchAction(term, 0, filters))
    }
}

const mapStateToProps = state => {
    return {
        filters: state.filters
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(History);