import React, {Component} from 'react';
import {connect} from 'react-redux';
import MyCheckbox from '../layout/Checkbox';
import { searchAction } from '../../store/actions/searchActions';
import { toggleModalAction, addYearsAction, addYearAction, removeYearAction, removeYearsAction } from '../../store/actions/filtersActions';

const range = (start, end) => Array.from({length: (end - start + 1)}, (v, k) => k + start);

class RORFilters extends Component{

    constructor(props){
        super(props)
        this.applyFilters = this.applyFilters.bind(this)
        this.handleYearCheckbox = this.handleYearCheckbox.bind(this)
        this.selectAllYears = this.selectAllYears.bind(this)
    }

    selectAllYears(e){
        if(e.target.checked){
            this.props.addYears([...range(1902, 1946), ...range(1961, 1982)])
        }
        else{
            this.props.removeYears()
        }
    }
    
    handleYearCheckbox(e){
        const year = parseInt(e.target.id.split('-')[2])
        if(e.target.checked){
            this.props.addYear(year)
        }
        else{
            this.props.removeYear(year)
        }
    }

    applyFilters(){
        this.props.perfromSearch( document.querySelector("#search_field").value,  
                                    this.props.filters )
        this.props.toggleModal(false)
    }
    
    render(){
        return <div className="">
            <span>Years</span>&nbsp;
            <MyCheckbox onChange={this.selectAllYears} label={"Select all"}/>
            <div className="display-flex">
                {[...range(1902, 1946), ...range(1961, 1982)].map(year => {
                    return <div 
                            key={year} 
                            className="width-content margin-left-40p"
                            >
                                <MyCheckbox
                                checked={this.props.filters.years.includes(year)} 
                                onChange={this.handleYearCheckbox} 
                                label={String(year)} 
                                id={"ror-year-" + year}/>
                            </div>
                })}
            </div>
            <div className="apply-filters-container">
                <button onClick={this.applyFilters} className="btn apply-filters-btn margin-auto display-block">Apply and Search</button>
            </div>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        perfromSearch: (term, filters) => dispatch(searchAction(term, 0, filters)),
        toggleModal: value => dispatch(toggleModalAction(value)),
        addYears: years => dispatch(addYearsAction(years)),
        addYear: year => dispatch(addYearAction(year)),
        removeYear: year => dispatch(removeYearAction(year)),
        removeYears: () => dispatch(removeYearsAction()),
        
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RORFilters)