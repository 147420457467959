export const setTheme = () => {
    if (!localStorage.getItem('theme-pref')){
        localStorage.setItem('theme-pref', "light")
    }
    else if (localStorage.getItem('theme-pref') === "dark"){
        document.body.className += " dark-mode"
    }
    else if (localStorage.getItem('theme-pref') === "light"){
        var classes = document.body.className.split(' ')
        var i = classes.indexOf('dark-mode')
        if(i >= 0){
            classes.splice(i, 1)
            document.body.className = classes.join(' ')
        }
    }
}
export const setLang = (lang) => {
    console.log("lang pref"+localStorage.getItem('lang-pref'))
    if (!localStorage.getItem('lang-pref')) {
        localStorage.setItem('lang-pref', "en"); // default to English
    } else {
        localStorage.setItem('lang-pref', lang);
    }
}