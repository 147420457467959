import { Link, Redirect } from "react-router-dom";
import {
  backendUrl,
  googleLoginPath,
  microsoftLoginPath,
} from "../backendInfo";
import { setTheme } from "../GlobalFunctions";
import React, { Component } from "react";
import { connect } from "react-redux";
import Logo from "./layout/Logo";

class Login extends Component {
  componentDidMount() {
    setTheme();
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return (
        <div className="height-100">
          <Logo size="large" />
          <div className="padding-top-5">
            <div className="login-text margin-auto width-content">
              <p >
                Minnatul Aleem - A Search Engine for Jamaat Books and
                Periodicals.
              </p>
            </div>
            <a
              id="google-signin"
              href={`${backendUrl}${googleLoginPath}`}
              className="z-depth-2 margin-auto login-btn waves-effect waves-light btn red lighten-1"
            >
              Sign in with Google
            </a>
            <br />
            <a
              id="microsoft-signin"
              href={`${backendUrl}${microsoftLoginPath}`}
              className="z-depth-2 margin-auto login-btn waves-effect waves-light btn blue lighten-1"
            >
              Sign in with Microsoft
            </a>
            <br />
          </div>
          <div className="login-footer">
            <Link to="/" className="margin-auto display-block width-content">
              Minnatul Aleem Search
            </Link>
            <p className="width-content margin-auto grey-text">
              Copyrights &#169; 2020. All rights reserved.
            </p>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Login);
