const initState = { // all selections
    isOpen: false,
    tab: "all_indices",
    qtype: '1',
    stype:"chronological",
    collections: [], //ids
    authors: [], //ids
    volumes: [], //col-vol
    years: [],
    chapters: [],
    months:[]
}


const filtersReducer = (state=initState, action) => {
    switch (action.type) {
        case 'TOGGLE_MODAL': {
            state = {
                ...state,
                isOpen: action.value
            }
            break;
        }
        case 'ADD_CHAPTER': {
            console.log('added..', action.chapter)
            state = {
                ...state,
                chapters: [...state.chapters, action.chapter]
            }
            break;
        }
        case 'REMOVE_CHAPTER': {
            console.log('removed..', action.chapter)
            state = {
                ...state,
                chapters: state.chapters.filter(c => c !== action.chapter)
            }
            break;
        }
        case 'ADD_COLLECTION': {
            state = {
                ...state,
                collections: [...state.collections, action.collection]
            }
            break;
        }
        case 'REMOVE_COLLECTION': {
            state = {
                ...state,
                collections: state.collections.filter(coll => coll !== action.collection)
            }
            break;
        }
        case 'ADD_AUTHOR': {
            state = {
                ...state,
                authors: [...state.authors, action.author]
            }
            break;
        }
        case 'REMOVE_AUTHOR': {
            state = {
                ...state,
                authors: state.authors.filter(a => a !== action.author)
            }
            break;
        }
        case 'ADD_VOLUME': {
            state = {
                ...state,
                volumes: [...state.volumes, action.vno]
            }

            break;
        }
        case 'REMOVE_VOLUME': {
            state = {
                ...state,
                volumes: state.volumes.filter(vno => vno !== action.vno)
            }
            break;
        }
        case 'ADD_VOLUMES': {
            state = {
                ...state,
                volumes: [...state.volumes, ...action.volumes]
            }
            break;
        }
        case 'ADD_AUTHORS': {
            state = {
                ...state,
                authors: [...state.authors, ...action.authors]
            }
            break;
        }
        case 'ADD_COLLECTIONS': {
            state = {
                ...state,
                collections: [...state.collections, ...action.collections]
            }
            break;
        }
        case 'REMOVE_COLLECTIONS': {
            state = {
                ...state,
                collections: []
            }
            break;
        }
        case 'REMOVE_AUTHORS': {
            state = {
                ...state,
                authors: []
            }
            break;
        }

        case 'REPLACE_ALL': {
            state = {
                ...state,
                collections: action.data.collections ? action.data.collections : state.collections,
                authors: action.data.authors ? action.data.authors : state.authors,
                volumes: action.data.volumes ? action.data.volumes : state.volumes,
                years: action.data.years ? action.data.years : state.years,
                months: action.data.months ? action.data.months : state.months,
                qtype: action.data.qtype ? action.data.qtype : state.qtype,
                stype: action.data.stype ? action.data.stype : state.stype

            }
            break;
        }
        case 'CHANGE_TAB': {
            state = {
                ...state,
                tab: action.tab
            }
            break;
        }
        // ror
        case 'ADD_YEAR': {
            state = {
                ...state,
                years: [...state.years, action.year]
            }
            break;
        }

        case 'REMOVE_YEAR': {
            state = {
                ...state,
                years: state.years.filter(y => y !== action.year)
            }
            break;
        }

        case 'ADD_YEARS': {
            state = {
                ...state,
                years: [...action.years]
            }
            break;
        }
        case 'REMOVE_YEARS': {
            state = {
                ...state,
                years: []
            }
            break;
        }
        case 'CHANGE_QTYPE': {
            state = {
                ...state,
                qtype: action.value
            }
            break;
        }
        case 'CHANGE_SORTORDER': {
            state = {
                ...state,
                stype: action.value
            }
            break;
        }
        case 'ADD_MONTH': {
            state = {
                ...state,
                months: [...state.months, action.month]
            }
            break;
        }
        case 'REMOVE_MONTH': {
            state = {
                ...state,
                months: state.months.filter(y => y !== action.month)
            }
            break;
        }

    }
    return state
}


export default filtersReducer;