const initState = {
    hits: null,
    openDetailsModal: false
}


const resultDetailsReducer = (state=initState, action) => {
    switch(action.type){
        case 'TOGGLE_DETAILS_MODAL': {
            state = {
                ...state,
                openDetailsModal: action.value
            }
            break;
        }
        case 'SHOW_RESULT_DETAILS': {
            state = {
                ...state,
                hits: action.details
            }
            break;
        }
        case 'CLEAR_DETAILS': {
            state = {
                ...state,
                hits: null
            }
            break;
        }
    }
    return state
}

export default resultDetailsReducer;