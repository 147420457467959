export const collectionList = [
  21,
  43,
  24,
  55,
  20,
  35,
  39,
  3,
  4,
  19,
  106,
  43,
  24,
  12,
  5,
  52,
  107,
  1,
  23,
  "40",
  14,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  36,
  37,
  33,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  82,
  83,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  101,
  102,
  105,
  106,
  107,
];
